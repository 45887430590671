<template>
  <button
    :class="[type, { disabled, small }]"
    v-on:click.prevent="$emit('click')"
    :disabled="disabled"
  >
    <font-awesome-icon class="icon-size" icon="plus" v-if="icon === 'plus'" />
    <router-link :to="link" v-if="link" class="button-link">
      {{ txt }}
    </router-link>
    <span class="" v-html="txt" v-else> </span>
  </button>
</template>

<style lang="less" scoped>
@secondary-green: var(--secondary-green);

button {
  // margin: 0 auto;
  padding: 10px 16px;
  position: relative;
  z-index: 1;
  border: 0px solid;
  border-radius: 5px;
  font-size: 1.2em;
  display: flex;
  display: -webkit-flex;
  -webkit-justify-content: center;
  -webkit-align-items: center;

  font-weight: 600;
  box-shadow: 0px 0px 10px -2px #cfb595;

  justify-content: center;
  align-items: center;
  cursor: pointer;
  outline: none;
  transition: 0.3s linear;
  color: black;

  a.button-link {
    color: black;
    width: 100%;
    height: 100%;
  }
  &.small {
    padding: 4px 12px;
    font-size: 1em;
  }

  &.primary {
    background: var(--primary-golden);
    // background: linear-gradient(#4cac55, #39793f);
    color: black;
    border-color: #5fb469;

    &:hover {
      // color: ;
      box-shadow: 0px 0px 20px -2px #cfb595;
    }
    // &::before {
    //   content: "";
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   z-index: -1;
    //   width: 100%;
    //   height: 100%;
    //   background: linear-gradient(#5bc465, #448b4b);
    //   opacity: 0;
    //   transition: 0.4s;
    // }

    // &:hover::before {
    //   opacity: 1;
    // }

    &.bold {
      font-weight: 700;
    }

    .fa-plus {
      margin-right: 10px;
    }
  } // end primary
  &.primary-dark {
    background: var(--secondary-green);
    // background: linear-gradient(#4cac55, #39793f);
    color: #fff;
    border-color: #5fb469;

    &:hover {
      // color: ;
      box-shadow: 0px 0px 15px -2px lighten(#2f6c3c, 10%);
    }
    // &::before {
    //   content: "";
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   z-index: -1;
    //   width: 100%;
    //   height: 100%;
    //   background: linear-gradient(#5bc465, #448b4b);
    //   opacity: 0;
    //   transition: 0.4s;
    // }

    // &:hover::before {
    //   opacity: 1;
    // }

    &.bold {
      font-weight: 700;
    }

    .fa-plus {
      margin-right: 10px;
    }
  } // end primary

  &.secondary {
    // background-color: #40578d;
    background: var(--primary-golden);
    color: black;

    // &::before {
    //   content: "";
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   z-index: -1;
    //   width: 100%;
    //   height: 100%;
    //   background: linear-gradient(#526ca8, #40567e);
    //   opacity: 0;
    //   transition: 0.4s;
    // }

    &:hover {
      // color: ;
      box-shadow: 0px 0px 20px -2px #cfb595;
    }
    &.bold {
      font-weight: 700;
    }
  } // end secondary
  &.approve {
    background-color: var(--primary-green);
    color: white;
  }
  &.deny {
    background-color: red;
    color: white;

    &:hover {
      // color: ;
      box-shadow: 0px 0px 15px -2px lighten(red, 10%);
    }
  }
  &.tertiary {
    // background-color: #40578d;
    background: var(--primary-orange);

    // background: linear-gradient(#40578d, #2f4265);
    color: #fff;

    // &::before {
    //   content: "";
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   z-index: -1;
    //   width: 100%;
    //   height: 100%;
    //   background: linear-gradient(#526ca8, #40567e);
    //   opacity: 0;
    //   transition: 0.4s;
    // }

    &:hover {
      // color: ;
      box-shadow: 0px 0px 15px -2px var(--primary-orange);
    }
    &.bold {
      font-weight: 700;
    }
  } // end secondary

  //gradient button
  &.gradient {
    background: rgb(68, 182, 120);
    background: linear-gradient(
      180deg,
      rgba(68, 182, 120, 1) 0%,
      rgba(43, 172, 217, 1) 100%
    );
    color: #fff;

    // }

    &:hover {
      // color: ;
      box-shadow: 0px 0px 15px -2px #2bacd9;
    }
    &.bold {
      font-weight: 700;
    }
  } // end gradient
  &.default {
    background-color: #ffffff;
    background: linear-gradient(#f7f7f7, #d6d1d1);
    color: #808080;
    border-color: #fff5f5;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      background: linear-gradient(#f8f7f7, #ebe4e4);
      opacity: 0;
      transition: 0.4s;
    }

    &:hover::before {
      opacity: 1;
    }

    &.bold {
      font-weight: 700;
    }
  } // end default

  &.secondary-default {
    background-color: #5a5a5a;
    background: linear-gradient(#5a5a5a, #323232);
    color: #ffffff;
    border-color: #6a6a6a;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      background: linear-gradient(#807e7e, #5e5d5d);
      opacity: 0;
      transition: 0.4s;
    }

    &:hover::before {
      opacity: 1;
    }

    &.bold {
      font-weight: 700;
    }
  }

  &.login {
    background-color: #157efb;
    color: #ffffff;
    border-color: #52a1ff;
    transition: 0.4s;

    &:hover {
      background-color: #398df5;
    }

    &.bold {
      font-weight: 700;
    }
  } // end login

  &.profile {
    // background-color: #40578d;
    background: var(--secondary-blue);
    color: #fff;
    // border-color: #5c7ab8;

    &.bold {
      font-weight: 700;
    }
  }

  &.cancel {
    background-color: #e60022;
    background: linear-gradient(#e60022, #97071c);
    color: #fff;
    border-color: #ec1f3e;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      background: linear-gradient(#e71333, #b31028);
      opacity: 0;
      transition: 0.4s;
    }

    &:hover::before {
      opacity: 1;
    }

    &.bold {
      font-weight: 700;
    }
  } // end cancel

  &.disabled {
    background: dimgrey !important;
    color: darkgray;
    box-shadow: none;
    cursor: default;

    &:hover {
      // color: ;
      box-shadow: none;
    }
  }
}
@media only screen and (max-width: 600px) {
  button {
    padding: 10px;
    font-size: 14px;
  }
}
</style>

<script>
import { library, parse } from "@fortawesome/fontawesome-svg-core";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faPlus);

export default {
  name: "diyobo-button",
  components: {
    FontAwesomeIcon,
  },
  props: {
    txt: String,
    type: String,
    link: String,
    disabled: Boolean,
    small: Boolean,
    icon: {
      default: "",
      type: String,
    },
  },
};
</script>
