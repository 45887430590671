<template>
  <modal title="Confirm" :active="active" v-on:close="close" ref="modal">
    <div class="alert-message">
      <p>{{ msg }}</p>
    </div>
    <template v-slot:footer>
      <div class="buttons">
        <diyobo-button
          type="secondary"
          :txt="cancel"
          v-on:click="close('cancel')"
        />
        <diyobo-button
          type="primary"
          :txt="confirm"
          v-on:click="close('confirm')"
        />
      </div>
    </template>
  </modal>
</template>

<script>
import Modal from "@/components/Modal.vue";
import DiyoboInput from "@/components/DiyoboInput.vue";
import DiyoboButton from "@/components/DiyoboButton.vue";

export default {
  name: "confirm-modal",
  components: {
    Modal,
    DiyoboInput,
    DiyoboButton,
  },
  data() {
    return {
      active: false,
      msg: "",
      confirm: "Confirm",
      cancel: "Cancel",
    };
  },
  methods: {
    open(msg, confirm, cancel, callback, val) {
      this.active = true;

      this.msg = msg;
      this.confirm = confirm != null ? confirm : this.confirm;
      this.cancel = cancel != null ? cancel : this.cancel;
      this.callback = callback != null ? callback : function (val) {};

      // this.$refs.modal.style.display = "block";
      document.body.style.overflow = "hidden";

      // this.active = true
    },
    close(evt) {
      let self = this;
      this.active = false;
      // this.$emit(evt)
      if (evt === "confirm") this.callback();
      setTimeout(function () {
        self.$refs.modal.style.display = "none";
      }, 500);
    },
    callback: function (val) {},
  },
};
</script>

<style lang="less" scoped>
.buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
</style>