<template>
  <modal title="Processing..." :active="active" v-on:close="$emit('cancel')">
    <h4 v-if="message">{{ message }}</h4>
    <div class="alert-message">
      <div class="loader"></div>
    </div>
    <div class="button-wrapper">
      <diyobo-button
        v-if="isCancel"
        type="secondary"
        txt="Cancel"
        v-on:click="$emit('cancel')"
      />
    </div>
  </modal>
</template>

<style lang="less" scoped>
.modal {
  .modal-inner {
    .modal-dialog {
      .modal-body {
        .alert-message {
          display: flex;
          justify-content: center;
        }

        .loader {
          border: 8px solid #f3f3f3;
          border-top: 8px solid var(--primary-golden);
          border-radius: 50%;
          width: 60px;
          height: 60px;
          animation: spin 1s ease-in-out infinite;
          margin-bottom: 20px;
        }

        @keyframes spin {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
      }
    }
  }
}
</style>

<script>
import Modal from "@/components/Modal.vue";
import DiyoboButton from "@/components/DiyoboButton.vue";

export default {
  name: "alert-modal",
  components: {
    Modal,
    DiyoboButton
  },
  props: {
    active: Boolean,
    message: String,
    isCancel: {
      type: Boolean,
      default: true
    }
  }
};
</script>
