<template>
  <modal
    title="Register for an Account"
    :active="active"
    v-on:close="$emit('close')"
  >
    <diyobo-input
      type="text"
      label="First Name"
      placeholder="Given Name"
      v-model="user.first"
      :val="user.first"
      :required="true"
      :error="errors[0].error"
    />
    <diyobo-input
      type="text"
      label="Last Name"
      placeholder="Surname"
      v-model="user.last"
      :val="user.last"
      :required="true"
      :error="errors[1].error"
    />
    <diyobo-input
      type="email"
      label="Email Address"
      placeholder="example@domain.com"
      v-model="user.email"
      :val="user.email"
      :required="true"
      :autocomplete="'off'"
      :error="errors[2].error"
      :msg="errors[2].message"
    />
    <diyobo-input
      type="password"
      label="Password"
      placeholder="Must contain 8 characters: 1 Capital, 1 number & 1 special character (! # @ $ * ONLY). Ex: Ticket478!"
      autocomplete="new-password"
      v-model="user.password"
      :val="user.password"
      :required="true"
      :error="errors[3].error"
      :msg="errors[3].message"
    />
    <diyobo-input
      type="password"
      label="Confirm Password"
      placeholder="Must contain 8 characters: 1 Capital, 1 number & 1 special character (! # @ $ * ONLY). Ex: Ticket478!"
      autocomplete="new-password"
      v-model="user.confirm"
      :val="user.confirm"
      :required="true"
      :error="errors[4].error"
    />
    <template v-slot:footer>
      <diyobo-button
        type="secondary"
        txt="Register"
        v-on:click="validateForm()"
      />
    </template>
  </modal>
</template>

<script>
import Modal from "@/components/Modal.vue";
import DiyoboInput from "@/components/DiyoboInput.vue";
import DiyoboButton from "@/components/DiyoboButton.vue";
import { emailCheck } from "../../helpers/input-formats";

export default {
  name: "register-modal",
  components: {
    Modal,
    DiyoboInput,
    DiyoboButton
  },
  props: {
    active: Boolean
  },
  data() {
    return {
      user: {
        first: null,
        last: null,
        email: null,
        password: null,
        confirm: null,
        website: null,
        phone: null,
        interests: [],
        new: true
      },
      errors: [
        { name: "first", error: false, message: null },
        { name: "last", error: false, message: null },
        { name: "email", error: false, message: null },
        { name: "password", error: false, message: null },
        { name: "confirm", error: false, message: null }
      ],
      error: null
    };
  },
  methods: {
    // validation
    validateForm() {
      this.$children.forEach((key, index) => {
        // we don't care about any other components except the diyobo-input component
        if (key.$options._componentTag == "diyobo-input" && key.$refs.input) {
          if (key.required && !key.$refs.input.value) {
            this.errors[index].error = true;
          } else {
            this.errors[index].error = false;
            this.errors[index].message = "";
          }
        }
      });

      if (!emailCheck(this.user.email)) {
        this.errors[2].error = true;
      }

      if (this.user.password != this.user.confirm) {
        this.errors[3].error = true;
        this.errors[4].error = true;
      } else {
        if (this.user.password) this.errors[3].error = false;
        if (this.user.confirm) this.errors[4].error = false;
      }

      for (let key in this.errors) {
        if (this.errors[key].error) {
          console.log(this.errors[key].error);
          return false;
        }
      }
      this.createUser().catch(e => {
        if (e.type === "duplicate user entry") {
          this.errors[2].error = true;
          this.errors[2].message = e.message;
        } else if (e.type === "password weak") {
          this.errors[3].error = true;
          this.errors[3].message = e.message;
          this.errors[4].error = true;
        }
      });
    },
    // create user
    async createUser() {
      let data;
      await this.$axios.post("/users/create", this.user).then(resp => {
        data = resp.data;
      });
      if (await data.error) throw await data.error;
      this.$emit("dismiss");
      this.$bus.$emit("alert", ModalContent.registerAfter);
    }
  }
};
</script>

<style lang="less" scoped>
</style>
