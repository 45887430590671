<template>
  <div>
    <div class="event" :class="{ active: expanded }">
      <processing-modal
        :message="displayLoadingMessage"
        :active="processing"
        v-on:cancel="closeModal(2)"
      />
      <TicketOptionsContactModal ref="contact" />
      <ShareModal ref="shareModal" :event="event" />
      <UpdateProfileName ref="profileName" />
      <div class="event-inner">
        <span class="purchased">
          {{ purchasedText }} on {{ purchasedDate }}
        </span>

        <div class="event-banner" ref="event-banner">
          <img
            class="event-image"
            :src="image"
            :alt="item.event_data[0].name"
            :class="{ 'all-refunded': item.allRefunded }"
          />
          <div class="share-event" @click="openShareModal">
            <img src="../../assets/icons/Icon-share.svg" alt="" />
          </div>
          <div class="labels">
            <div
              class="label"
              v-for="(label, index) in orderLabels"
              :key="index"
            >
              {{ label }}
            </div>
          </div>
        </div>
        <div class="full-wrapper">
          <div class="contents">
            <div class="event-title">{{ item.event_data[0].name }}</div>
            <div class="event-date" v-if="!item.event_data[0].privateVenue">
              {{ item.event_data[0].city }},
              {{ item.event_data[0].region_code }},
              {{ item.event_data[0].country_name }}
            </div>
            <div class="event-date">
              <span class="date">
                <svg
                  width="30"
                  height="31"
                  viewBox="0 0 30 31"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M26.25 13.3694H3.75M20 3.3694V8.3694M10 3.3694V8.3694M9.75 28.3694H20.25C22.3502 28.3694 23.4003 28.3694 24.2025 27.9607C24.9081 27.6011 25.4817 27.0275 25.8413 26.3219C26.25 25.5197 26.25 24.4696 26.25 22.3694V11.8694C26.25 9.7692 26.25 8.7191 25.8413 7.91694C25.4817 7.21133 24.9081 6.63765 24.2025 6.27813C23.4003 5.8694 22.3502 5.8694 20.25 5.8694H9.75C7.6498 5.8694 6.5997 5.8694 5.79754 6.27813C5.09193 6.63765 4.51825 7.21133 4.15873 7.91694C3.75 8.7191 3.75 9.7692 3.75 11.8694V22.3694C3.75 24.4696 3.75 25.5197 4.15873 26.3219C4.51825 27.0275 5.09193 27.6011 5.79754 27.9607C6.5997 28.3694 7.6498 28.3694 9.75 28.3694Z"
                    stroke="var(--primary)"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                {{ formattedDate }}
              </span>
              <span class="time">
                <svg
                  width="30"
                  height="31"
                  viewBox="0 0 30 31"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15 8.3694V15.8694L20 18.3694M27.5 15.8694C27.5 22.773 21.9036 28.3694 15 28.3694C8.09644 28.3694 2.5 22.773 2.5 15.8694C2.5 8.96584 8.09644 3.3694 15 3.3694C21.9036 3.3694 27.5 8.96584 27.5 15.8694Z"
                    stroke="var(--primary)"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                {{ formattedTime }}
              </span>
            </div>
            <div class="dropdown-button">
              <div class="tags">
                <div
                  v-for="(ticket, index) in ticketTagType()"
                  :key="index"
                  class="tag"
                  @click="processingPopup(ticket)"
                >
                  {{ ticket }}
                </div>
              </div>
              <!-- <span v-if="expanded === false" @click="expand('MENU')">
                <img
                  class="cog"
                  src="../../assets/icons/General-cog.svg"
                  alt=""
                />
              </span> -->
              <span @click="expand('MENU')">
                <svg
                  width="24"
                  height="27"
                  viewBox="0 0 24 27"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="cog"
                  :class="{ expanded: expanded }"
                >
                  <path
                    d="M8.74393 23.0833L9.47448 24.7263C9.69165 25.2154 10.0461 25.631 10.4948 25.9227C10.9434 26.2143 11.4671 26.3695 12.0023 26.3694C12.5374 26.3695 13.0611 26.2143 13.5098 25.9227C13.9584 25.631 14.3129 25.2154 14.53 24.7263L15.2606 23.0833C15.5206 22.5003 15.9581 22.0142 16.5106 21.6944C17.0666 21.3737 17.7098 21.2371 18.3481 21.3041L20.1356 21.4944C20.6677 21.5507 21.2047 21.4514 21.6814 21.2086C22.1582 20.9657 22.5543 20.5898 22.8217 20.1263C23.0894 19.6632 23.217 19.1322 23.1889 18.598C23.1608 18.0638 22.9782 17.5492 22.6634 17.1166L21.605 15.6625C21.2282 15.1408 21.0268 14.5129 21.03 13.8694C21.0299 13.2276 21.2332 12.6023 21.6106 12.0833L22.6689 10.6291C22.9838 10.1966 23.1663 9.68199 23.1944 9.14774C23.2225 8.6135 23.095 8.08258 22.8273 7.6194C22.5599 7.15594 22.1638 6.78001 21.687 6.53718C21.2102 6.29436 20.6732 6.19506 20.1411 6.25134L18.3536 6.44162C17.7153 6.50866 17.0721 6.37205 16.5161 6.05134C15.9626 5.72972 15.525 5.2411 15.2661 4.65551L14.53 3.01246C14.3129 2.52336 13.9584 2.10779 13.5098 1.81615C13.0611 1.5245 12.5374 1.36931 12.0023 1.3694C11.4671 1.36931 10.9434 1.5245 10.4948 1.81615C10.0461 2.10779 9.69165 2.52336 9.47448 3.01246L8.74393 4.65551C8.48509 5.2411 8.04752 5.72972 7.49392 6.05134C6.93794 6.37205 6.29476 6.50866 5.65643 6.44162L3.86337 6.25134C3.33128 6.19506 2.79429 6.29436 2.31751 6.53718C1.84073 6.78001 1.44464 7.15594 1.17726 7.6194C0.909534 8.08258 0.781987 8.6135 0.810092 9.14774C0.838197 9.68199 1.02075 10.1966 1.33559 10.6291L2.39392 12.0833C2.77135 12.6023 2.9746 13.2276 2.97448 13.8694C2.9746 14.5112 2.77135 15.1365 2.39392 15.6555L1.33559 17.1097C1.02075 17.5422 0.838197 18.0568 0.810092 18.5911C0.781987 19.1253 0.909534 19.6562 1.17726 20.1194C1.4449 20.5826 1.84104 20.9583 2.31776 21.2011C2.79447 21.4439 3.33132 21.5434 3.86337 21.4875L5.65087 21.2972C6.28921 21.2301 6.93239 21.3667 7.48837 21.6875C8.04403 22.0082 8.48363 22.4969 8.74393 23.0833Z"
                    stroke="#98A2B3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M12 17.6194C14.0711 17.6194 15.75 15.9405 15.75 13.8694C15.75 11.7983 14.0711 10.1194 12 10.1194C9.92896 10.1194 8.25002 11.7983 8.25002 13.8694C8.25002 15.9405 9.92896 17.6194 12 17.6194Z"
                    stroke="#98A2B3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <!-- <img
                  class="cog"
                  src="../../assets/icons/General-cog.svg"
                  alt=""
                  style="color: var(--primary-golden)"
                /> -->
              </span>
            </div>
          </div>
        </div>
        <div class="img-cont">
          <img id="img" src="../../assets/Ticket_Logo3.png" alt />

          <img
            id="invoice-logo"
            src="../../assets/TicketTote-Logo-Black.png"
            alt
          />
          <canvas id="canvas"></canvas>
          <canvas id="canvas1" width="410px" height="400px"></canvas>
          <diyobo-button type="primary" txt="Pdf" id="pdfbtn" />
        </div>
        <PDFViewer
          v-if="pdf"
          :src="pdf"
          :file-name="fileName"
          :numPages="pdfPages"
          :invoice="invoicePdf"
          @close="closePDFViewer"
        />
      </div>
    </div>
    <div class="ticket-options" :class="{ active: expanded }">
      <div class="tickets-options-inner" v-if="dropdownMode === 'MENU'">
        <div v-if="canPrintInvoice" v-on:click="processingPopup('inv')">
          <font-awesome-icon class="ticket-options-icon" icon="paperclip" />
          Receipt
        </div>
        <!-- <div v-else class="disabled">
          <font-awesome-icon
            class="ticket-options-icon disabled"
            icon="paperclip"
          />
          Invoice
        </div> -->
        <div
          v-if="
            hasTickets && !item.isAttendee && !isAllProducts && !isAllDonations
          "
          v-on:click="openAttendeeLink"
        >
          <font-awesome-icon class="ticket-options-icon" icon="users" />
          Manage Attendees
        </div>
        <!-- <div v-else class="disabled">
          <font-awesome-icon
            class="ticket-options-icon disabled"
            icon="users"
          />
          Manage Attendees
        </div> -->

        <div v-on:click="openContactModal('tickets')">
          <font-awesome-icon
            class="ticket-options-icon"
            icon="question-circle"
          />
          Ticket Questions
        </div>
        <div v-on:click="openContactModal('planner')">
          <font-awesome-icon
            class="ticket-options-icon"
            icon="question-circle"
          />
          Event Questions
        </div>
        <div v-if="upgradeEnabled" v-on:click="openUpgradePage">
          <font-awesome-icon class="ticket-options-icon" icon="upload" />
          Upgrade
        </div>
        <!-- <div v-else class="disabled">
          <font-awesome-icon
            class="ticket-options-icon disabled"
            icon="upload"
          />
          Upgrade
        </div> -->

        <div v-if="exchangeEnabled" v-on:click="openExchangePage">
          <font-awesome-icon class="ticket-options-icon" icon="exchange-alt" />
          Exchange
        </div>
        <!-- <div v-else class="disabled">
          <font-awesome-icon
            class="ticket-options-icon disabled"
            icon="exchange-alt"
          />
          Exchange
        </div> -->
        <div v-if="refundEnabled" v-on:click="openRefundPage">
          <font-awesome-icon class="ticket-options-icon" icon="step-backward" />
          Refund Request
        </div>
        <div v-if="transferEnabled" v-on:click="openTransferPage">
          <font-awesome-icon class="ticket-options-icon" icon="upload" />
          Transfer Ticket
        </div>
        <!-- <div v-else class="disabled">
          <font-awesome-icon
            class="ticket-options-icon disabled"
            icon="step-backward"
          />
          Refund Request
        </div> -->
        <!-- <div class="disabled">
          <font-awesome-icon
            class="ticket-options-icon disabled"
            icon="hand-point-right"
          />
          Transfer
        </div> -->
        <div v-on:click="openEventPage">
          <font-awesome-icon class="ticket-options-icon" icon="calendar" />
          Event Page
        </div>

        <div v-if="item.waiver_info" @click="processingPopup('waiver')">
          <font-awesome-icon class="ticket-options-icon" icon="file" />
          Waiver
        </div>
        <!-- <div class="disabled" v-else>
          <font-awesome-icon class="ticket-options-icon disabled" icon="file" />
          Waiver
        </div> -->
      </div>

      <div
        class="qr-options"
        v-if="dropdownMode === 'QRCODE' && qrCodes.length > 0"
      >
        <div class="qr-options-inner">
          <div
            class="left"
            v-if="qrCodes.length > 1"
            @click="previousQR"
            :class="{ disabled: currentQrCodeIndex === 0 }"
          >
            <img src="../../assets/icons/Icon-left.svg" alt="" />
          </div>
          <div class="qr-code-container">
            <div class="ticket-number" v-if="!guestlistOrder">
              Ticket #: {{ currentQrCode.ticketNumber }}
            </div>
            <div class="ticket-number" v-if="!guestlistOrder">
              Tier: {{ currentQrCode.tier }}
            </div>
            <img
              :src="currentQrCode.img"
              alt=""
              class="qr-code"
              :class="currentQrCode.attendee ? 'attendee' : 'notAttendee'"
            />
            <div class="attendee-info" v-if="currentQrCode.attendee">
              <span class="title">Assigned to Attendee</span>
              <span class="email">{{ currentQrCode.attendee }}</span>
            </div>
            <div class="collapse" @click="expand('QRCODE')">
              <div>
                <img src="../../assets/icons/Icon-top.svg" alt="" />
              </div>
              <span>COLLAPSE</span>
              <div>
                <img src="../../assets/icons/Icon-top.svg" alt="" />
              </div>
            </div>
          </div>
          <div
            class="right"
            v-if="qrCodes.length > 1"
            @click="nextQR"
            :class="{ disabled: currentQrCodeIndex === qrCodes.length - 1 }"
          >
            <img src="../../assets/icons/Icon-right.svg" alt="" />
          </div>
        </div>
      </div>
      <!-- <div v-if="canPrintTicket" v-on:click="processingPopup('tix')">
          <font-awesome-icon class="ticket-options-icon" icon="ticket-alt" />
          In-Person Tickets
        </div>
        <div v-else class="disabled">
          <font-awesome-icon
            class="ticket-options-icon disabled"
            icon="ticket-alt"
          />
          In-Person Tickets
        </div> -->
      <!-- <div class="ticket-options-right">
        <div v-if="showDashboardAccess" v-on:click="openMagicLink">
          <font-awesome-icon class="ticket-options-icon" icon="desktop" />
          Dashboard Access
        </div>
        <div v-else class="disabled">
          <font-awesome-icon
            class="ticket-options-icon disabled"
            icon="desktop"
          />
          Dashboard Access
        </div>

       
        <div
          v-if="hasGuestlist && guestlistApproved"
          @click="processingPopup('guestlist')"
        >
          <font-awesome-icon class="ticket-options-icon" icon="bed" />
          Guest List Tickets
        </div>
        <div class="disabled" v-else>
          <font-awesome-icon class="ticket-options-icon disabled" icon="bed" />
          Guest List Tickets
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import UpdateProfileName from "../modals/UpdateProfileName.vue";
import DiyoboButton from "@/components/DiyoboButton.vue";
import dateFormat from "dateformat";
import ProcessingModal from "@/components/modals/ProcessingModal.vue";
import PdfModal from "@/components/modals/PdfModal.vue";
import PDFViewer from "@/components/PDFViewer.vue";
import { viewWillCall } from "@/helpers/tickets.js";
import dayjs from "dayjs";
import Timezones from "dayjs/plugin/timezone";
import TicketOptionsContactModal from "../modals/TicketOptionsContactModal.vue";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faArrowCircleUp,
  faBed,
  faCalendar,
  faChevronDown,
  faChevronUp,
  faDesktop,
  faExchangeAlt,
  faFile,
  faGift,
  faHandPointRight,
  faInfoCircle,
  faMoneyBill,
  faPaperclip,
  faQuestionCircle,
  faStepBackward,
  faTicketAlt,
  faUpload,
  faUsers,
  faClock,
} from "@fortawesome/free-solid-svg-icons";
import QRCode from "qrcode";
import ShareModal from "../modals/ShareModal.vue";

const comps_type = "Complimentary Ticket";
const LOADING_MESSAGE =
  "It may take a few moments to grab your tickets since you bought so many, you rockstar!!!";
const MENU = "MENU";
const QRCODE = "QRCODE";

library.add(
  faTicketAlt,
  faDesktop,
  faPaperclip,
  faUsers,
  faQuestionCircle,
  faUpload,
  faChevronUp,
  faChevronDown,
  faExchangeAlt,
  faStepBackward,
  faHandPointRight,
  faCalendar,
  faBed,
  faFile,
  faInfoCircle,
  faGift,
  faArrowCircleUp,
  faMoneyBill,
  faClock
);

dayjs.extend(Timezones);

export default {
  name: "event-ticket",
  components: {
    UpdateProfileName,
    DiyoboButton,
    ProcessingModal,
    PdfModal,
    PDFViewer,
    FontAwesomeIcon,
    TicketOptionsContactModal,
    ShareModal,
  },
  props: {
    refund: Boolean,
    name: String,
    startDate: Date,
    purchased: String,
    image: String,
    invoice: {
      type: String,
      default: "None",
    },
    endDate: Date,
    owner: String,
    ticket: Array,
    item: Object,
    event: Object,
    orderId: String,
    isSpecialEvent: { type: Boolean, default: false },
  },
  data() {
    return {
      expanded: false,
      eventName: this.item.event_data[0].name,
      processing: false,
      pdfProcess: false,
      pdf: null,
      pdfPages: 1,
      fileName: "",
      timezone: "",
      invoicePdf: false,
      user: [],
      qrCodes: [],
      currentQrCodeIndex: 0,
      tempIndex: 0,
      dropdownMode: "MENU",
    };
  },
  methods: {
    nextQR() {
      if (this.currentQrCodeIndex < this.qrCodes.length - 1) {
        this.currentQrCodeIndex++;
      }
    },
    previousQR() {
      if (this.currentQrCodeIndex > 0) {
        this.currentQrCodeIndex--;
      }
    },
    openShareModal() {
      this.$refs.shareModal.open({
        title: "Share this Event",
        closable: true,
      });
    },
    openContactModal(type) {
      const contactTitle =
        type === "tickets" ? "Ticket Questions" : "Event Questions";

      this.$refs.contact.open({
        contactType: type,
        title: contactTitle,
        closable: true,
        url: this.item.event_data[0].url,
      });
      this.onConfirm = () => {};
    },
    ticketTagType() {
      let tickets = this.ticket.slice(0, 4);
      let unique = [];
      tickets.forEach((tix) => {
        if (tix.ticketType == "IN_PERSON_RESERVED") {
          if (!unique.includes("IN_PERSON")) {
            unique.push("IN_PERSON");
          }
        } else {
          if (!unique.includes(tix.ticketType)) {
            unique.push(tix.ticketType);
          }
        }
      });
      // let unique = [...new Set(tickets.map((ticket) => ticket.ticketType))]; // [ 'A', 'B']

      if (this.hasGuestlist) {
        tickets.slice(0, 3);
        unique.push("Guest List");
      }

      return unique;
    },

    closePDFViewer() {
      this.pdf = null;
      this.invoicePdf = false;
    },
    nameBrowser() {
      if (
        (navigator.userAgent.indexOf("Opera") ||
          navigator.userAgent.indexOf("OPR")) != -1
      ) {
        return "Opera";
      } else if (navigator.userAgent.indexOf("Chrome") != -1) {
        return "Chrome";
      } else if (navigator.userAgent.indexOf("Safari") != -1) {
        return "Safari";
      } else if (navigator.userAgent.indexOf("Firefox") != -1) {
        return "Firefox";
      } else if (
        navigator.userAgent.indexOf("MSIE") != -1 ||
        !!document.documentMode == true
      ) {
        return "IE"; //crap
      } else {
        return "Unknown";
      }
    },

    processingPopup(val) {
      this.processing = true;

      setTimeout(() => {
        switch (val) {
          case "inv":
            this.viewInvoice();
            break;
          case "waiver":
            this.viewWaiver();
            break;
          case "slip":
            this.viewWillCall();
            break;
          case "Guest List":
            this.viewGuestlist();

            break;
          case "IN_PERSON":
            this.viewTickets();
            break;
          case "VIRTUAL":
            this.openMagicLink();
            this.processing = false;
            break;
          default:
            this.viewTickets();
            break;
        }
      }, 500);
    },
    openEventPage() {
      window.open(`${this.item.event_data[0].url}`, "_blank");
    },
    openUpgradePage() {
      window.open(
        `/tote-swap/upgrade/${this.item.event_data[0].url}`,
        "_blank"
      );
    },
    openTransferPage() {
      window.open(`/tote-swap/transfer`, "_blank");
    },
    openExchangePage() {
      window.open(
        `/tote-swap/exchange/${this.item.event_data[0].url}`,
        "_blank"
      );
    },
    openRefundPage() {
      window.open(`/tote-swap/refund/${this.item.event_data[0].url}`, "_blank");
    },
    openMagicLink() {
      if (
        this.$store.state.user.first_name == "" &&
        this.$store.state.user.last_name == ""
      ) {
        this.$refs.profileName.open({
          link: this.item.access_link,
        });
      } else {
        if (this.item.access_link) {
          window.open(this.item.access_link, "_blank");
        } else if (this.item.allVirtualAssigned) {
          this.$toast("All virtual tickets have been assigned to attendees.", {
            type: "error",
          });
        } else {
          this.$toast("No virtual link found.", { type: "error" });
        }
      }
    },
    openAttendeeLink() {
      let comp = this.item.comp || false;
      if (this.hasTickets && !this.item.isAttendee && !this.isSubAttendee) {
        this.$router.push({
          path: `/attendee-info/attendees`,
          query: {
            id: this.orderId,
            comp: comp,
          },
        });
        // window.open(
        //   `attendee-info/attendees?id=${this.orderId}&comp=${comp}`,
        //   "_blank"
        // );
      }
    },
    viewInvoice() {
      let self = this;
      this.$axios
        .get(`/tickets/get-order-invoice-pdf?order=${this.item._id}`, {
          headers: {
            Accept: "application/pdf",
          },
          responseType: "blob",
        })
        .then((resp) => {
          let data = resp.data;

          setTimeout(() => {
            const blobPDF = new Blob([data], {
              type: "application/pdf",
            });

            var reader = new FileReader();
            reader.readAsBinaryString(blobPDF);

            reader.onloadend = function () {
              var count = reader.result.match(/\/Type[\s]*\/Page[^s]/g).length;
              self.pdfPages = count;
            };

            const blobUrl = URL.createObjectURL(blobPDF);
            self.fileName = "receipt.pdf";
            self.pdf = blobUrl;
          }, 500);
          self.invoicePdf = true;
          self.closeModal(2);
        })
        .then((resp) => {
          this.processing = false;
        });
    },
    expand(type) {
      if (type !== this.dropdownMode && this.expanded) {
        this.expanded = false;
      }

      this.dropdownMode = type;
      if (this.expanded) this.expanded = false;
      else this.expanded = true;
    },
    close() {
      this.expanded = false;
    },
    closeModal() {
      let body = document.getElementsByTagName("body");
      body[0].style.overflow = "visible";

      this.processing = false;
    },
    viewWaiver() {
      let self = this;

      this.$axios
        .post(
          "/tickets/get-order-waiver-pdf",
          {
            order: self.item._id,
          },
          {
            headers: {
              Accept: "application/pdf",
            },
            responseType: "blob",
          }
        )
        .then(({ data }) => {
          setTimeout(() => {
            const blobPDF = new Blob([data], {
              type: "application/pdf",
            });

            var reader = new FileReader();
            reader.readAsBinaryString(blobPDF);

            reader.onloadend = function () {
              var count = reader.result.match(/\/Type[\s]*\/Page[^s]/g).length;
              self.pdfPages = count;
            };

            const blobUrl = URL.createObjectURL(blobPDF);
            self.fileName = "waiver.pdf";
            self.pdf = blobUrl;
            self.invoicePdf = true;
          }, 500);
          self.closeModal(2);
        });
    },
    viewGuestlist() {
      let self = this;
      this.qrCodes = [];

      this.$axios
        .post("/tickets/get-order-guestlist-pdf", {
          order: self.item.guestlist_id,
          timezone: self.item.event_data[0].timezone,
        })
        .then(({ data }) => {
          setTimeout(() => {
            let ticketObj = {
              ticketNumber: 1,
              img: data,
            };

            self.qrCodes.push(ticketObj);
            self.closeModal(2);
            this.expand(QRCODE);
          }, 500);
        });
    },
    viewTickets() {
      let self = this;
      let comps = this.item.comp ?? false;
      var opts = {
        errorCorrectionLevel: "H",
        type: "image/png",
        quality: 1,
        margin: 1,
      };
      this.qrCodes = [];
      if (this.$store.state.user.email !== this.item.account_email) {
        let filteredTickets = this.ticket.filter(
          (t) =>
            (t.attendee && t.attendee.email === this.$store.state.user.email) ||
            !t.attendee
        );
        filteredTickets.forEach((t) => {
          let ticketObj = {
            tier: t.tierName,
            ticketNumber: t.number,
            img: "",
          };

          let qrObj = {
            _id: this.item.event_id,
            name: this.item.name,
            orderId: this.orderId,
            validation: t.validation,
            comps,
          };

          let objJson = JSON.stringify(qrObj);

          QRCode.toDataURL(objJson, opts, function (err, url) {
            if (err) throw err;
            ticketObj.img = url;
          });

          this.qrCodes.push(ticketObj);
        });
      } else {
        // array1.filter(email => array2.includes(email));

        this.ticket.forEach((t) => {
          if (t.ticketType === "VIRTUAL") {
            return;
          }

          let ticketObj = {
            tier: t.tierName,
            ticketNumber: t.number,
            img: "",
          };
          let qrObj = {};

          if (
            t.hasOwnProperty("attendee") &&
            t.attendee.email !== this.$store.state.user.email
          ) {
            ticketObj = { ...ticketObj, attendee: t.attendee.email };
            qrObj = {
              name: "This is not a valid qr code 😒",
            };
          } else {
            qrObj = {
              _id: this.item.event_id,
              name: this.item.name,
              orderId: this.orderId,
              validation: t.validation,
              comps,
            };
          }

          let objJson = JSON.stringify(qrObj);

          QRCode.toDataURL(objJson, opts, function (err, url) {
            if (err) throw err;
            ticketObj.img = url;
          });

          self.qrCodes.push(ticketObj);
        });
      }
      this.closeModal();
      this.expand(QRCODE);
    },
    viewWillCall() {
      let canvas = document.getElementById("canvas");
      const ticket = this.item;
      let address = this.item.event_data[0].venueAddress;

      viewWillCall(
        ticket,
        this.item.event_data[0],
        canvas,
        address,
        (tickets) => {
          this.pdf = tickets.pdf;
          this.fileName = tickets.fileName;
          this.closeModal(2);
        }
      );
    },
    formatDate(date, format) {
      let tz = dayjs.tz.guess();
      if (date.$date) {
        let newDate = date.$date.$numberLong;
        return dayjs(+newDate).format(
          format ? format : "MMM DD, YYYY, hh:mm A"
        );
      } else {
        let newDate = date;
        return dayjs(newDate).format(format ? format : "MMM DD, YYYY, hh:mm A");
      }
    },
  },
  mounted() {
    this.timezone = dayjs.tz.guess();
    this.user = this.$store.state.user;

    // console.clear();
  },
  computed: {
    currentQrCode() {
      return this.qrCodes[this.currentQrCodeIndex];
    },
    purchasedText() {
      if (this.item.comp || this.item.willcall) {
        return "Received";
      } else if (
        Object.keys(this.item).includes("guestlist_id") &&
        this.item.guestlist_id &&
        this.item.status
      ) {
        return (
          this.item.status.charAt(0).toUpperCase() + this.item.status.slice(1)
        );
      } else {
        return "Purchased";
      }
    },
    orderLabels() {
      let labels = [];

      if (this.isUpgraded) {
        labels.push("Upgraded");
      }
      if (this.isExchanged) {
        labels.push("Exchanged");
      }
      if (this.isTransferred) {
        labels.push("Transferred");
      }
      return labels;
    },
    isUpgraded() {
      if (this.item.tickets.length > 0) {
        return this.item.tickets.every((ticket) =>
          ticket.hasOwnProperty("upgrade")
        );
      } else {
        return false;
      }
    },
    isExchanged() {
      if (this.item.tickets.length > 0) {
        return this.item.tickets.some((ticket) =>
          ticket.hasOwnProperty("exchange")
        );
      } else {
        return false;
      }
    },
    isTransferred() {
      if (this.item.tickets.length > 0) {
        let allTransfer = this.item.tickets.every((ticket) =>
          ticket.hasOwnProperty("transfer")
        );

        let orderTransfer = this.item.transferred;

        return allTransfer || orderTransfer;
      } else {
        return false;
      }
    },
    exchangeEnabled() {
      if (this.item.allRefunded) {
        return false;
      }
      if (this.item.transferred || this.item.transferred_copy) {
        return false;
      }

      return this.item.event_data[0].enable_exchange_ticket;
    },
    upgradeEnabled() {
      if (this.item.allRefunded) {
        return false;
      }
      if (this.item.transferred || this.item.transferred_copy) {
        return false;
      }
      return this.item.event_data[0].enable_upgrade_ticket;
    },
    transferEnabled() {
      if (this.item.allRefunded) {
        return false;
      }
      if (this.item.transferred || this.item.transferred_copy) {
        return false;
      }
      return this.item.event_data[0].enable_transfer_ticket;
    },

    refundEnabled() {
      if (this.item.allRefunded) {
        return false;
      }
      if (this.item.transferred || this.item.transferred_copy) {
        return false;
      }
      return this.item.event_data[0].enable_refund_ticket;
    },
    isAllProducts() {
      return this.item.tickets.every((t) => t.product === true);
    },
    hasTickets() {
      return this.item.tickets.length > 0;
    },
    hasGuestlist() {
      if (
        Object.keys(this.item).includes("guestlist_id") &&
        this.item.guestlist_id
      ) {
        return true;
      } else {
        return false;
      }
    },
    guestlistApproved() {
      return this.hasGuestlist && this.item.status === "approved";
    },
    guestlistOrder() {
      if (
        Object.keys(this.item).includes("guestlist_items") &&
        this.item.guestlist_items.length > 0
      ) {
        return true;
      } else {
        return false;
      }
    },
    isAllDonations() {
      return this.item.tickets.every((t) => t.ticketType === "DONATION");
    },
    displayLoadingMessage() {
      return this.ticket.length >= 20 ? LOADING_MESSAGE : "";
    },
    formatName: {
      get: function () {
        if (this.item.event_data[0].name.length > 28) {
          return this.item.event_data[0].name.substr(0, 27) + "...";
        } else {
          return this.item.event_data[0].name;
        }
      },
    },
    formattedDate() {
      return dateFormat(this.startDate, "ddd mmm dd, yyyy");
    },
    formattedTime() {
      return `${this.formatDate(this.startDate, "hh:mm A")} - ${this.formatDate(
        this.endDate,
        "hh:mm A"
      )}`;
    },

    purchasedDate() {
      return this.formatDate(this.purchased);
    },

    showDashboardAccess() {
      if (
        this.isSpecialEvent &&
        this.ticket.some((t) => t.ticketType === "VIRTUAL") &&
        !this.item.allRefunded
      ) {
        return true;
      }
      return false;
    },
    canPrintTicket() {
      if (this.ticket.every((t) => t.ticketType === "VIRTUAL")) {
        return false;
      }
      if (this.ticket.every((t) => t.ticketType === "DONATION")) {
        return false;
      }

      if (
        !this.refund &&
        this.ticket.filter((t) => !t.product).length > 0 &&
        !this.item.willcall &&
        !this.item.allRefunded
      ) {
        return true;
      } else {
        return false;
      }
      return true;
    },
    canPrintInvoice() {
      if (this.hasGuestlist && !this.guestlistOrder) {
        return false;
      }
      if (this.item.comp || this.item.willcall || this.item.isAttendee) {
        return false;
      }
      if (this.item.transferred) {
        return false;
      }
      return true;
    },
    isOwner() {
      return this.$store.state.user.email === this.item.account_email;
    },
  },
};
</script>

<style lang="less" scoped>
.full-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;

  .contents {
    width: 100%;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.event {
  flex-grow: unset;
  position: relative;
  min-height: 211px;

  overflow: hidden;
  border: 1px solid #475467;
  border-radius: 10px;
  background: #31343a;
  &.active {
    border-radius: 8px 8px 0 0;
    border-bottom: 0;
  }

  .event-inner {
    position: relative;
    padding: 5px 10px 10px 10px;
    display: flex;
    flex-wrap: wrap;

    .purchased {
      font-size: 10px;
      text-align: center;
      margin: auto;
      color: #98a2b3;
      font-style: normal;
      font-weight: 500;
      line-height: 150%; /* 15px */
      letter-spacing: 0.15px;
    }
    .img-cont {
      display: none;
    }

    .event-banner {
      display: flex;
      width: 100%;
      aspect-ratio: 16/9;
      overflow: hidden;
      position: relative;
      margin: 5px 0;

      .labels {
        position: absolute;
        bottom: 6px;
        right: 6px;
        display: flex;

        .label {
          cursor: pointer;
          font-size: 10px;
          font-weight: bold;
          padding: 4px 8px;
          -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(2px);
          border-radius: 20px;
          background: var(--black-50, rgba(0, 0, 0, 0.5));
        }
      }

      img.event-image {
        width: 100%;
        height: 100%;
        transition: 0.4s;
        // border-bottom: 1px solid var(--event-border);
        border-radius: 10px;
      }

      .share-event {
        position: absolute;
        right: 8px;
        top: 8px;
        border-radius: 8px;
        padding: 5px;
        background: var(--black-50, rgba(0, 0, 0, 0.5));
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        img {
          height: 20px;
          width: 20px;
        }
      }

      .all-refunded {
        background: gray;
        opacity: 0.2;
      }
    }

    // end event-banner

    .full-wrapper {
      .contents {
        .event-title {
          font-family: inherit;
          font-size: 14px;
          font-weight: bold;
        }

        .event-date {
          // color: var(--primary-golden);
          // font-family: inherit;
          // font-size: 14px;
          color: #98a2b3;
          display: flex;
          justify-content: space-between;
          margin: 10px 0;

          svg {
            width: 16px;
            height: 16px;
          }

          .date,
          .time {
            display: flex;
            align-items: center;
            gap: 5px;
          }
        }

        .purchase-date {
          font-size: 14px;
          color: var(--subtitle);
        }

        .dropdown-button {
          margin-top: 4px;
          display: flex;
          justify-content: space-between;

          .cog {
            cursor: pointer;
            transition: 0.5s ease;
            width: 22px;
            height: 22px;
            &.expanded {
              path {
                stroke: var(--primary);
                stroke-width: 2px;
              }

              transform: rotate(-90deg);
            }
          }

          .tags {
            width: 100%;
            margin-right: 8px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            column-gap: 5px;

            div {
              background-color: var(--primary);
              max-height: 20px;
              max-width: 75px;
              display: flex;
              padding: 5px 10px;
              justify-content: center;
              align-items: center;
              flex-grow: 1;
              border-radius: 50px;
              font-size: 10px;
              font-weight: bold;
              overflow: hidden;
              line-height: 100%;
              cursor: pointer;
            }
          }
        }
      }
    }

    .invoice-css {
      margin-top: 5px;
      margin-bottom: 6px;
    }
  }
}

.ticket-options {
  // display: none;
  overflow: hidden;
  border-radius: 0 0 10px 10px;
  position: absolute;
  width: 100%;
  font-size: 12px;
  max-height: 0;
  opacity: 0;
  background-color: #323439;
  z-index: 1;
  border: 1px solid #485467;
  border-top: 0;

  transition:
    max-height 0.4s,
    opacity 0.2s ease-in;

  &.active {
    max-height: 500px;
    opacity: 1;
    display: flex;
    flex-direction: column;
    transition:
      max-height 0.4s,
      opacity 0.2s ease-out;

    // padding: 10px;
  }
  .tickets-options-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    background: var(--card-background);
    border-radius: 10px;
    margin: 10px;
    margin-top: 0;
    div:not(:last-child) {
      border-bottom: 1px solid var(--event-border);
    }

    div {
      padding: 10px;
      transition: 0.4s;
      svg {
        margin-right: 5px;
      }
      &:hover:not(.disabled) {
        padding-left: 15px;
        // border-radius: ;
        // color: var(--text);
        // background-color: var(--subtitle);
      }

      &.disabled {
        cursor: default;
        color: var(--gray-9);
        box-shadow: none;
        // padding-left: 4px;
      }
    }
  }

  .qr-options {
    background: var(--card-background);
    border: 1px solid #344054;
    border-radius: 10px;
    padding: 10px;
    margin: 10px;
    margin-top: 0;
    text-align: center;

    .ticket-number {
      font-size: 12px;
      color: #98a2b3;
      line-height: 150%;
      letter-spacing: 0.8px;
    }
    .qr-options-inner {
      display: flex;
      justify-content: center;
      align-items: center;

      .left,
      .right {
        img {
          cursor: pointer;
          padding: 3px 6px;
        }

        &.disabled {
          img {
            opacity: 0.4;
            cursor: not-allowed;
          }
        }
      }

      .qr-code-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin: auto;
        position: relative;
      }

      .attendee-info {
        position: absolute;
        background: rgba(0, 0, 0, 0.5);
        padding: 12px;
        border: 1px solid #1d2939;
        border-radius: 10px;
        max-width: 85%;

        .title {
          color: #fff;
          text-align: center;

          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 100%;
        }
        .email {
          display: block;
          color: var(--primary);
          text-align: center;

          font-size: 12px;
          font-style: normal;
          font-weight: bold;
          line-height: 100%;
        }
      }
      .qr-code {
        width: 113px;
        height: 113px;
        margin: auto;
        background: white;
        margin: 5px 0;

        img {
          width: 100%;
        }

        &.attendee {
          opacity: 0.1;
        }
      }
    }

    .collapse {
      display: flex;
      column-gap: 10px;
      justify-content: center;
      align-items: center;
      width: max-content;
      cursor: pointer;
      font-weight: 600;
      font-size: 8px;
      margin-top: 5px;
      color: #98a2b3;
      line-height: 150%;
      letter-spacing: 0.6px;
    }
  }
  // .ticket-options-left,
  // .ticket-options-right {
  //   display: flex;
  //   flex-direction: column;
  //   justify-content: center;
  //   cursor: pointer;
  //   width: 50%;

  //   div:not(:last-child) {
  //     border-bottom: 1px solid var(--event-border);
  //   }

  //   div {
  //     border-right: 1px solid var(--event-border);
  //     padding: 4px;
  //     transition: 0.3s;

  //     &:hover:not(.disabled) {
  //       padding-left: 8px;
  //       color: var(--text);
  //       background-color: var(--subtitle);
  //     }

  //     &.disabled {
  //       cursor: default;
  //       color: var(--gray-9);
  //       box-shadow: none;
  //       padding-left: 4px;
  //     }
  //   }
  // }

  .ticket-options-right > div {
    border-right: 0;
  }

  .ticket-options-icon {
    color: var(--primary-golden);

    &.disabled {
      cursor: default;
      color: var(--subtitle);
    }
  }
}
</style>
