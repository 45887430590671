<template>
  <div class="video-player-container">
    <div class="video-player" id="draggable" v-show="helpVideo && videoHidden">
      <div class="draggable-icon">
        <font-awesome-icon icon="arrows-alt" />
      </div>
      <div class="close-icon">
        <font-awesome-icon icon="times" @click="closeVideo" />
      </div>
      <iframe
        v-if="videoHidden"
        width="100%"
        height="100%"
        :src="helpVideo"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write;
     encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
  </div>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { faTimes, faArrowsAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
library.add(faTimes, faArrowsAlt);

export default {
  name: "help-video",
  components: {
    FontAwesomeIcon
  },
  props: {
    helpVideo: String
  },
  data() {
    return {
      videoHidden: false,
      mobile: false
    };
  },
  methods: {
    closeVideo() {
      this.videoHidden = false;
    },
    showVideo() {
      this.videoHidden = !this.videoHidden;
      this.mobile = false;

      if (this.helpVideo) {
        var dragStartX, dragStartY;
        var objInitLeft, objInitTop;
        var inDrag = false;
        let dragTarget = document.querySelector("#draggable");

        if (dragTarget) {
          dragTarget.addEventListener("mousedown", function(e) {
            inDrag = true;
            objInitLeft = dragTarget.offsetLeft;
            objInitTop = dragTarget.offsetTop;
            dragStartX = e.pageX;
            dragStartY = e.pageY;
          });
          document.addEventListener("mousemove", function(e) {
            if (!inDrag) {
              return;
            }
            dragTarget.style.left = objInitLeft + e.pageX - dragStartX + "px";
            dragTarget.style.top = objInitTop + e.pageY - dragStartY + "px";
          });
          document.addEventListener("mouseup", function(e) {
            inDrag = false;
          });
        }
      }
    }
  }
};
</script>

<style lang="less" scoped>
.video-player-container {
  background: var(--content-background);
}

.video-player {
  padding: 30px 10px 0;
  position: fixed;
  bottom: 16px;
  width: 100px;
  background-color: var(--content-background);
  right: 16px;
  width: 410px;
  height: 265px;
  z-index: 999;

  .draggable-icon {
    cursor: grab;
    position: absolute;
    left: 2px;
    top: 18px;
    border: 1px solid white;
    width: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 26px;
    border-radius: 50%;
  }
  .close-icon {
    cursor: pointer;
    position: absolute;
    right: 2px;
    top: 18px;
    /* font-size: 20px; */
    border: 1px solid white;
    width: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 26px;
    border-radius: 50%;
  }

  @media screen and (max-width: 930px) {
    right: 22px;
    bottom: 95px;
    z-index: 1;
    width: 80%;
    height: 350px;
    margin: auto;
    padding: 30px 0 0;

    // .draggable-icon {
    //   display: none;
    // }
  }
  @media screen and (max-width: 730px) {
    width: 90%;
  }
  @media screen and (max-width: 600px) {
    width: 90%;
    height: 250px;
  }
}
</style>
