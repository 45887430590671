var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"Content"}},[_c('div',{staticClass:"stepper-container"},[_vm._l((_vm.steps),function(step,index){return _c('div',{key:index,staticClass:"step-container",class:{
        'non-linear': !_vm.nonLinear,
        firststep: index === 0
      }},[(index != 0)?_c('div',{staticClass:"progress-divider",class:{
          complete: step.complete,
          incomplete: !step.complete && step.active
        }},[_c('div',{staticClass:"inner-color"})]):_vm._e(),(!_vm.nonLinear)?_c('div',{staticClass:"step-name",class:{ complete: step.complete },on:{"click":function($event){return _vm.goto(step)}}},[(!step.complete)?_c('span',{staticClass:"step"},[_vm._v(" "+_vm._s(step.number)+" ")]):_c('span',{staticClass:"step"},[_c('font-awesome-icon',{attrs:{"icon":"check"}})],1),_c('span',{staticClass:"name"},[_vm._v(" "+_vm._s(step.name))])]):_c('div',{staticClass:"step-name",on:{"click":function($event){return _vm.changeStep(step.number)}}},[(!step.complete)?_c('span',{staticClass:"step"},[_vm._v(" "+_vm._s(step.number)+" ")]):(step.complete && step.active)?_c('span',{staticClass:"step"},[_c('font-awesome-icon',{attrs:{"icon":"pen"}})],1):_c('span',{staticClass:"step"},[_c('font-awesome-icon',{attrs:{"icon":"check"}})],1),_c('span',{staticClass:"name"},[_vm._v(" "+_vm._s(step.name))])])])}),_c('span',{staticClass:"animation"})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }