export function formatPhoneNumber(phone, edit) {
  console.log("3");
  if (edit == "delete") {
    return phone;
  }

  phone = phone.replace(/\D/g, "");
  phone = phone.substring(0, 10);

  let length = phone.length;

  if (length < 10) {
    phone = phone;
  } else {
    if (length == 0) {
      phone = "";
    } else if (length < 4) {
      phone = "(" + phone;
    } else if (length < 7) {
      phone = "(" + phone.substring(0, 3) + ") " + phone.substring(3, 6);
    } else {
      phone =
        "(" +
        phone.substring(0, 3) +
        ") " +
        phone.substring(3, 6) +
        " - " +
        phone.substring(6, 10);
    }
  }
  return phone;
}

export function postalFormat(post, country) {
  var correctPost;

  if (country == "Canada") {
    console.log("1");
    post = post.replace(/\s/g, "");
    correctPost = post.substring(0, 3) + " " + post.substring(3, 6);

    return correctPost;
  } else if (country == "U.S.A") {
    console.log("2");
    post = post.replace(/\D/g, "");
    correctPost = post.substring(0, 5);
    return correctPost;
  } else {
    console.log("3");
    return post;
  }
}

export function creditFormat(card) {
  card = card.replace(/\D/g, "");

  if (card.length >= 19) {
    let format = card.slice(0, 19);
    return format;
  }

  if (card.length == 0) {
    card = "";
  } else if (card.length < 5) {
    card = card;
  } else if (card.length < 9) {
    card = card.substring(0, 4) + "-" + card.substring(4, 8);
  } else if (card.length < 13) {
    card =
      card.substring(0, 4) +
      "-" +
      card.substring(4, 8) +
      "-" +
      card.substring(8, 12);
  } else {
    card =
      card.substring(0, 4) +
      "-" +
      card.substring(4, 8) +
      "-" +
      card.substring(8, 12) +
      "-" +
      card.substring(12, 16);
  }

  return card;
}

export function cardExpiryFormat(val) {
  val = val.replace(/\D/g, "");

  if (val.length < 3) {
    val = val.substring(0, 2);
  } else {
    val = val.substring(0, 2) + "/" + val.substring(2, 4);
  }

  return val;
}

export function cardCVVFormat(val) {
  val = val.replace(/\D/g, "");

  return val;
}

export function dateFormat(date) {
  if (date.length >= 5) {
    let newDate = date.slice(0, 5);
    date = newDate;

    let firstBlock = date.substr(0, 2);
    let secondBlock = date.substr(3, 2);

    return firstBlock + "/" + secondBlock;
  }

  switch (date.length) {
    case 2:
      date += "-";
      break;
    case 4:
      date += "-";
      break;
    default:
      break;
  }

  return date;
}

export function emailCheck(email) {
  var pattern = /\S+@\S+\.\S+/;
  if (pattern.test(email)) {
    return true;
  } else {
    return false;
  }
}

export function websiteCheck(site) {
  if (site == "") {
    return true;
  }

  var pattern =
    /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;

  if (pattern.test(site)) {
    return true;
  } else {
    return false;
  }
}

export function inputPhoneNumberOnly(element, callBackFunc) {
  let phoneOutput = "";
  element.addEventListener("keydown", function (e) {
    if (event.keyCode != 8 && event.keyCode != 46) {
      if (!parseInt(e.key) && e.key != "0") {
        console.log("failed");
        e.target.setAttribute("readonly", true);
      }
    }
  });
  element.addEventListener("keyup", function (event) {
    if (event.keyCode == 8 || event.keyCode == 46) {
      phoneOutput = formatPhoneNumber(event.target.value, "delete");
    } else {
      let phone = event.target.value.replace(/\D/g, "");
      if (phone.length < 10) {
        phoneOutput = formatPhoneNumber(event.target.value);
      } else {
        phoneOutput = formatPhoneNumber(event.target.value);
      }
    }
    if (event.target.hasAttribute("readonly"))
      event.target.removeAttribute("readonly");
    callBackFunc(phoneOutput);
  });
}
