import Vue from "vue";
import Axios from "axios";
import VueMeta from "vue-meta";
import * as Sentry from "@sentry/vue";
import "@/styles/_formulate.less";
import VueFormulate from "@braid/vue-formulate";
import VMask from "v-mask";
import VCalendar from "v-calendar";
import VueQuillEditor from "vue-quill-editor";
import Toast, { POSITION } from "vue-toastification";
import { Integrations } from "@sentry/tracing";
import dayjs from "dayjs";
import App from "./App.vue";
import router from "./router/index.js";
import store from "./store/index.js";
import "./registerServiceWorker";
import { LoaderManager } from "@/helpers/loader";
import { NotificationManager } from "@/helpers/notifications";
import Card from "@/components/Card.vue";
import ClientOnly from "@/components/ClientOnly.vue";
import "vue-toastification/dist/index.css";
import vGoogleTranslate from "v-google-translate";
import vuetify from "./plugins/vuetify";
import { convert } from "@/helpers/currencyConversion";
import VueCookies from "vue-cookies";
import * as VueGoogleMaps from "gmap-vue";
import VueGoogleAutoComplete from "vue-google-autocomplete";
import VueHead from "vue-head";
import pdf from "vue-pdf-embed/dist/vue2-pdf-embed";
import "./plugins/maps";
import "./plugins/mytickets";
import VueLazyload from "vue-lazyload";
import ShareNetwork from "./components/ShareNetwork.vue";

Vue.config.productionTip = false;
Vue.prototype.$axios = Axios;
Vue.prototype.$bus = new Vue();
Vue.prototype.$loader = new LoaderManager(store);
Vue.prototype.$notifications = new NotificationManager();

Axios.defaults.baseURL = process.env.VUE_APP_SERVICE_URL.slice(0, -1);

Axios.defaults.headers.common = {
  "X-Whitelabel-Id": process.env.VUE_APP_WHITELABEL_ID || null,
};
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyBcexezrA_GnkA1uAOFi4KNpQGSzbP7nbM",
    libraries: "places",
  },
});

Vue.use(VueMeta, {
  keyName: "head",
});
Vue.use(VMask);
Vue.use(VCalendar);
Vue.use(Toast, {
  position: POSITION.BOTTOM_LEFT,
  maxToasts: 3,
  newestOnTop: true,
});
Vue.use(VueFormulate, {
  classes: {
    input: (context, classes) =>
      context.hasErrors ? classes.concat("error") : classes,
  },
  library: {
    checkbox: {
      classification: "box",
      component: () => import("@/components/inputs/TTCheckboxInput"),
    },
    date: {
      classification: "text",
      component: () => import("@/components/inputs/TTDateInput"),
      slotProps: {
        component: ["minDate", "maxDate", "dateForPage"],
      },
    },
    dateTime: {
      classification: "text",
      component: () => import("@/components/inputs/TTDateInput"),
      slotProps: {
        component: ["minDate", "maxDate", "dateForPage"],
      },
    },
    emoji: {
      classification: "emoji",
      component: () => import("@/components/inputs/TTEmojiInput"),
    },
    publishDate: {
      classification: "text",
      component: () => import("@/components/inputs/TTDateInput"),
      slotProps: {
        component: ["minDate", "maxDate", "dateForPage", "publishDate"],
      },
    },
    googleAutocomplete: {
      classification: "text",
      component: () => import("@/components/inputs/TTGoogleAutocompleteInput"),
      slotProps: {
        component: ["country"],
      },
    },
    image: {
      classification: "file",
      component: () => import("@/components/inputs/TTImageInput"),
      slotProps: {
        component: ["clearable"],
      },
    },
    link: {
      classification: "text",
      component: () => import("@/components/inputs/TTLinkInput"),
    },
    money: {
      classification: "text",
      component: () => import("@/components/inputs/TTMoneyInput"),
      slotProps: {
        component: ["small"],
      },
    },
    multiSelect: {
      classification: "select",
      component: () => import("@/components/inputs/TTMultiSelectInput"),
      slotProps: {
        component: ["bundle", "useCountLabel"],
      },
    },
    multiSelectGroup: {
      classification: "select",
      component: () => import("@/components/inputs/TTMultiselectGroup"),
      slotProps: {
        component: ["groupValuesField", "groupLabelField"],
      },
    },
    select: {
      classification: "select",
      component: () => import("@/components/inputs/TTSelectInput"),
    },
    selectSearch: {
      classification: "select",
      component: () => import("@/components/inputs/TTSelectSearch"),
    },
    submit: {
      classification: "submit",
      component: () => import("@/components/TTButton"),
      slotProps: {
        component: ["loading", "download"],
      },
    },

    tel: {
      classification: "text",
      component: () => import("@/components/inputs/TTPhoneInput"),
    },
    text: {
      classification: "text",
      component: () => import("@/components/inputs/TTTextInput"),
      slotProps: {
        component: ["small"],
      },
    },
    textPrevent: {
      classification: "text",
      component: () => import("@/components/inputs/TTTextInputPrevent"),
      slotProps: {
        component: ["small"],
      },
    },
    textArea: {
      classification: "textarea",
      component: () => import("@/components/inputs/TTTextArea"),
    },
    time: {
      classification: "text",
      component: () => import("@/components/inputs/TTDateInput"),
      slotProps: {
        component: ["minDate", "maxDate"],
      },
    },
    wysiwyg: {
      classification: "text",
      component: () => import("@/components/inputs/TTWYSYWIG"),
      slotProps: {
        component: ["inputHeight"],
      },
    },
    number: {
      classification: "number",
      component: () => import("@/components/inputs/TTNumberInput"),
    },
  },
  slotComponents: {
    label: () => import("@/components/TTLabel"),
    remove: () => import("@/components/TTRemoveButton"),
  },
  slotProps: {
    label: ["labelStyle"],
  },
  validationNameStrategy: ["validationName", "label", "name", "type"],
});
Vue.use(VueQuillEditor);

Vue.use(vGoogleTranslate);
Vue.component("vue-cookies", VueCookies);
Vue.component("vue-google-autocomplete", VueGoogleAutoComplete);
Vue.component("vue-head", VueHead);
Vue.component("pdf", pdf);

Vue.use(VueLazyload, {
  lazyComponent: true,
});
Vue.component("ShareNetwork", ShareNetwork);
Vue.component("Card", Card);
Vue.component("ClientOnly", ClientOnly);
Vue.directive("click-outside", {
  bind: function (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      // here I check that click was outside the el and his children
      if (!(el == event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener("click", el.clickOutsideEvent);
  },
  unbind: function (el) {
    document.body.removeEventListener("click", el.clickOutsideEvent);
  },
});

// Sentry.init({
//   Vue,
//   dsn: "https://389df35b3c5f428ab2f412fb8f21c0f5@o1081988.ingest.sentry.io/6090006",
//   environment: process.env.VUE_APP_ENVIRONMENT,
//   // integrations: [
//   //   new Integrations.BrowserTracing({
//   //     routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//   //     tracingOrigins: ["localhost", "planners.incredevent.com", "planners.staging.incredevent.com", /^\//],
//   //   }),
//   // ],
//   tracesSampleRate: 0.25,
//   logErrors: true,
//   enabled: process.env.NODE_ENV !== "development",
// });

Vue.mixin({
  methods: {
    formatDate(date, format = "ddd, MMM DD, YYYY") {
      if (date.$date) {
        date = +date.$date.$numberLong;
      } else {
        date = +date;
      }

      return dayjs(date).format(format);
    },
    formatMoney(money, eventCurrencyCode = "cad") {
      return `$${parseFloat(money).toFixed(2)}`;

      const cadOption = this.$store.state.cadCurrencyOption;
      const usdOption = this.$store.state.usdCurrencyOption;
      const currentStoreCurrency = this.$store.state.currency;

      if (cadOption === null && usdOption === null) {
        return;
      }
      let currency = null;

      if (eventCurrencyCode === "usd") {
        currency = usdOption.find((x) => x.label === currentStoreCurrency.name);
      } else {
        currency = cadOption.find((x) => x.label === currentStoreCurrency.name);
      }

      return convert(1, parseFloat(money), "$");
    },
    createBuyersLink(link, whitelabel = null) {
      if (whitelabel && whitelabel.domainName) {
        if (!whitelabel.domainName.startsWith("http")) {
          whitelabel.domainName = "https://" + whitelabel.domainName;
        }

        switch (process.env.VUE_APP_ENVIRONMENT) {
          case "development":
            return `${whitelabel.domainName}/${link}`;
          case "staging":
            return `${whitelabel.domainName}/${link}`;
          default:
            return `${whitelabel.domainName}/${link}`;
        }
      } else {
        switch (process.env.VUE_APP_ENVIRONMENT) {
          case "development":
            return `http://localhost:3100/${link}`;
          case "staging":
            return `https://staging.incredevent.com/${link}`;
          default:
            return `https://incredevent.com/${link}`;
        }
      }
    },
  },
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
