<template>
  <div class="banner">
    <router-link to="/login-register">
      Welcome to the New Worre Studios Ticketing Portal. Create an Account here.
    </router-link>
    <upper-navbar :account-links="accountLinks" />
    <!-- <router-link to="/blog">
      Exciting new features coming to INCREDEVENT.
    </router-link> -->
  </div>
</template>

<script>
import UpperNavbar from "@/components/UpperNavbar.vue";

export default {
  name: "site-wide-banner",
  props: {
    accountLinks: Array
  },
  components: {
    UpperNavbar
  }
};
</script>

<style lang="less" scoped>
.banner {
  position: sticky;
  top: 0;
  justify-content: center;
  width: 100%;
  height: 55px;
  display: flex;
  font-weight: 600;
  align-items: center;
  z-index: 50;
  background: var(--primary-golden);

  &.stepper {
    top: 80px;
  }
  a {
    color: black;
  }
}
@media screen and (max-width: 730px) {
  .banner {
    &.stepper {
      top: 72px;
    }
  }
}

@media screen and (max-width: 600px) {
  .banner {
    height: 25px;
    font-size: 2.5vw;
    padding: 0 8px;
  }
  // a {
  // }
}
@media screen and (max-width: 400px) {
  .banner {
    font-size: 2.5vw;
  }
  // a {
  // }
}
</style>
