<template>
  <div>
    <alert-modal ref="alert"/>
    <modal
      class="alert"
      :active="active"
      :title="title"
      :confirm="confirm"
      :closable="closable"
      v-on:close="close"
    >
      <FormulateForm ref="form" name="contactForm" v-model="formValues" @submit="submit">
        <FormulateInput
          type="text"
          name="first_name"
          label="First Name"
          placeholder="First Name"
          validation="required"
          autocomplete="false"
        />
        <FormulateInput
          type="text"
          name="last_name"
          label="Last Name"
          placeholder="Last Name"
          validation="required"
          autocomplete="false"
        />

        <div class="buttons">
            <FormulateInput
              class="button"
              type="submit"
              :name="confirm"
            />
        </div>
      </FormulateForm>
    </modal>
  </div>
</template>

<script>
import Modal from "@/components/Modal.vue";
import DiyoboButton from "@/components/DiyoboButton.vue";
import AlertModal from "@/components/modals/AlertModal.vue";
import dayjs from "dayjs";



export default {
  name: "UpdateProfileName",
  components: {
    Modal,
    DiyoboButton,
    AlertModal
  },
  data() {
    return {
      title: "Please add your first name and last name.",
      confirm: "Save",
      active: false,
      onConfirm: null,
      link: '',
      closable: false,
      formValues: {
        first_name: '',
        last_name: '',
      }
    }
  },
  methods: {
    open(args) {
      this.active = true;
      this.link = args.link;
      document.body.style.overflow = "hidden";
    },
    async submit() {
      await this.$axios
        .post("/buyers/update-profile-name", {
          first_name: this.formValues.first_name,
          last_name: this.formValues.last_name,
        })
        .then(response => {
          this.$store.state.user.first_name = this.formValues.first_name;
          this.$store.state.user.last_name = this.formValues.last_name;
          if (this.link) {
            window.open(this.link, "_blank");
          }
          this.close();
        })
        .catch(error => console.log(error));

    },
    close() {
      this.active = false;
    },
  }
}
</script>

<style lang="less" scoped>
.alert {
  z-index: 1001;

  .modal-footer {
    button {
      margin-left: 16px;
    }
  }
}

.buttons {
  display: inline-flex;
  justify-content: space-between;
  .action-btns {
    .button {
      margin-left: 20px;
    }
  }
}
</style>
