<template>
  <transition name="fade" appear>
    <div class="slider-wrapper">
      <div class="slider-container">
        <div class="slider-content">
          <div class="slider-info" :sliderMsg="sliderMsg">
            {{ sliderMsg }}
          </div>
          <div class="slider-exit">
            <button v-on:click="$emit('dismiss')">&times;</button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<style lang="less" scoped>
.slider-wrapper {
  width: 100%;
  position: fixed;
  z-index: 100;
  .slider-container {
    max-width: 1100px;
    margin: auto;
    background-color: #4a639e;
    border-radius: 18px;
    .slider-content {
      position: relative;
      .slider-info {
        padding: 23px;
        color: white;
      }
      .slider-exit {
        position: absolute;
        right: 16px;
        top: 16px;
        // height: min-content;
        button {
          color: var(--text);
          background-color: #4a639e;
          border: none;
          font-size: 2em;
          width: 30px;
          height: 35px;
          //    height: min-content;
          cursor: pointer;
          text-align: center;
          padding: 0px 0px 0px 0px;
        }
        button:hover {
          background-color: #e60022;
          border-radius: 18px;
        }
      }
    }
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.1s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
}
</style>

<script>
export default {
  name: "pop-up-slider",
  props: {
    sliderMsg: String,
  },
};
</script>
