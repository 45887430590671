<template>
  <transition
    v-on:beforeEnter="beforeEnter"
    v-on:enter="enter"
    v-on:afterEnter="afterEnter"
    v-on:beforeLeave="beforeLeave"
    v-on:leave="leave"
  >
    <div
      v-if="active"
      class="modal"
      :class="{ active }"
      v-on:click="onClickOutside"
    >
      <div class="modal-inner">
        <div class="modal-dialog" ref="dialog" :style="{ maxWidth: width }">
          <div class="modal-header">
            <div class="modal-header-box">
              <h3 v-if="title">{{ title }}</h3>
            </div>
            <!-- <button class="close" v-if="closable" v-on:click="close">
              &times;
            </button> -->
          </div>
          <div class="modal-body">
            <div class="modal-content b1">
              <slot></slot>
            </div>
            <div class="modal-footer" v-if="footer">
              <slot v-if="[title] != `Account Locked`" name="footer"></slot>
              <button
                v-on:click="close()"
                style="
              background-color: gray;
              border: 1px solid gray;
              color: darkgray;
              position: absolute;
              left: 19px;"
                class="custom-button"
                v-if="[title] == `Account Locked`"
                name="footer"
              >
                Close
              </button>
              <a href="mailto:411@incredevent.com"
                ><button
                  class="custom-button"
                  v-if="[title] == `Account Locked`"
                  name="footer"
                >
                  Contact
                </button></a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import DiyoboButton from "@/components/DiyoboButton.vue";

export default {
  name: "modal",
  components: {
    DiyoboButton,
  },
  props: {
    active: Boolean,
    title: String,
    closable: {
      type: Boolean,
      default: true,
    },
    footer: {
      type: Boolean,
      default: true,
    },
    width: {
      type: String | Number,
      default: "496px",
    },
  },
  methods: {
    beforeEnter(el) {
      this.$refs.dialog.style.top = "-160%";
      el.style.opacity = 0;
      document.body.style.overflow = "hidden";
    },
    enter(el) {
      el.style.opacity = 1;
    },
    afterEnter() {
      this.$refs.dialog.style.top = "50px";
    },
    beforeLeave() {
      this.$refs.dialog.style.top = "-120%";
    },
    leave(el) {
      el.style.opacity = 0;
      document.body.removeAttribute("style");
    },
    onClickOutside(e) {
      if (
        this.closable &&
        this.$refs.dialog &&
        !this.$refs.dialog.contains(e.target)
      ) {
        this.close();
      }
    },
    close() {
      this.$emit("close");
      setTimeout(() => {
        // this.$refs.dialog.removeAttribute('style')
        document.body.style.overflow = "";
      }, 500);
    },
  },
};
</script>

<style lang="less" scoped>
.modal {
  /*position absolute gets nested in parent components when they have position relative*/
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.5s;

  &.active {
    opacity: 1;

    .modal-inner {
      .modal-dialog {
        top: 0;
      }
      // end modal-dialog
    }
    // end modal-inner
  }

  .modal-inner {
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: var(--modal-bg);
    // #d9d9d9c4

    .modal-dialog {
      // padding: 2px;
      position: relative;
      top: -100%;
      width: 100%;
      max-width: 496px;
      max-height: 70%;
      // background-color: #4a639e;
      border: 1px solid var(--modal-border);
      background-image: var(--modal-bg);
      border-radius: 5px;
      //overflow: auto;
      transition: top 0.2s;

      .modal-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px 16px 4px 16px;
        min-height: 10px;
        background-color: var(--content-background);
        // box-shadow: 1px 1px 2px -1px #ffffff26, inset 1px 1px 3px 0px #00000026;
        border-radius: 5px 5px 0 0;

        h2,
        h3 {
          margin: 0 !important;
          font-weight: bold;
          color: var(--text);
        }

        &-box {
          background-color: var(--input-background);
          text-transform: uppercase;
          padding: 16px;
          border-radius: 8px;
          width: 100%;
        }

        .close {
          padding: 0;
          position: relative;
          font-size: 2em;
          color: #666666;
          background: transparent;
          border: none;
          cursor: pointer;
          -webkit-appearance: none;
          outline: none;
        }
      }

      .modal-body {
        padding: 4px 16px 16px 16px;
        // box-shadow: inset 0 0 3px 1px #0000008a;
        background-color: var(--content-background);
        border-radius: 0 0 5px 5px;

        .modal-content {
          margin-bottom: 20px;

          // &::v-deep div {
          //   margin-bottom: 18px;
          // }
        }
        // end modal-content

        .modal-footer {
          display: flex;
          justify-content: flex-end;
          column-gap: 16px;
        }
        // end modal-footer

        .error-label {
          margin-bottom: 0.75em;
          color: #e60022;
          text-align: center;
          font-weight: 600;
        }
      }
      // end modal-body
    }
    // end modal-dialog
  }
  // end modal-inner
}
// end modal

.custom-button {
  background-color: var(--primary);
  padding: 10px 16px;
  border-radius: 5px;
  border: 1px solid var(--primary);
  font-weight: 600;
  outline: 0px;
  font-size: 1em;
  color: white;
  cursor: pointer;
}
</style>
