<template>
  <div id="Content">
    <div class="stepper-container">
      <div
        class="step-container"
        v-for="(step, index) in steps"
        :key="index"
        :class="{
          'non-linear': !nonLinear,
          firststep: index === 0
        }"
      >
        <!-- for step-container when we use non-linear methods -->
        <!-- active: activeStep === index + 1 -->
        <!-- @click="goto(step)" -->

        <div
          class="progress-divider"
          v-if="index != 0"
          :class="{
            complete: step.complete,
            incomplete: !step.complete && step.active
          }"
        >
          <div class="inner-color"></div>
        </div>
        <div
          class="step-name"
          v-if="!nonLinear"
          @click="goto(step)"
          :class="{ complete: step.complete }"
        >
          <span class="step" v-if="!step.complete">
            {{ step.number }}
          </span>

          <span class="step" v-else>
            <font-awesome-icon icon="check"> </font-awesome-icon>
          </span>
          <span class="name"> {{ step.name }}</span>
        </div>
        <div class="step-name" @click="changeStep(step.number)" v-else>
          <span class="step" v-if="!step.complete">
            {{ step.number }}
          </span>
          <span class="step" v-else-if="step.complete && step.active">
            <font-awesome-icon icon="pen"> </font-awesome-icon>
          </span>
          <span class="step" v-else>
            <font-awesome-icon icon="check"> </font-awesome-icon>
          </span>

          <span class="name"> {{ step.name }}</span>
        </div>

        <!-- :class="{ incomplete: !steps[index + 1].complete && step.active }" -->
      </div>
      <span class="animation"></span>
    </div>
  </div>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { faTimes, faCheck, faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
library.add(faTimes, faCheck, faPen);
import Lottie, { lottie } from "lottie-web";

let fireworks = require("@/components/fireworks.json");
let fireworks2 = require("@/components/fireworks2.json");

export default {
  name: "stepper",
  components: {
    FontAwesomeIcon
  },
  props: {
    steps: Array,
    nonLinear: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      currentStep: 1
    };
  },
  methods: {
    goto(step) {
      if (step.link) {
        this.$router.push(step.link);
      }
    },
    changeStep(step) {
      this.steps.forEach(step => {
        step.active = false;
      });
      this.steps[step - 1].active = true;
      this.currentStep = step;
    },
    lastStepComplete() {
      const svgContainer = document.querySelector(".animation");
      if (svgContainer.childElementCount < 1) {
        svgContainer.style.zIndex = 1;
        svgContainer.style.display = "flex";

        let confettiAnim = Lottie.loadAnimation({
          container: svgContainer,
          renderer: "svg",
          loop: false,
          name: "fireworks",
          autoplay: false,
          animationData: fireworks
        });

        confettiAnim.play();

        setTimeout(() => {
          confettiAnim.destroy("fireworks");
          svgContainer.style.zIndex = 0;
          svgContainer.style.display = "none";

          this.$emit("stepperComplete");
        }, 4000);
      }
    }
  },
  watch: {
    steps: {
      handler(val) {
        let lastStep = val[val.length - 1];
        if (lastStep.complete) {
          setTimeout(() => {
            this.lastStepComplete();
          }, 2000);
        }
      },
      deep: true
    }
  },
  computed: {
    lastStep() {
      return this.steps.length - 1;
    },

    activeStep() {
      return this.currentStep;
    }
  }
};
</script>

<style lang="less" scoped>
#Content {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 50;
  min-height: initial;
  background: var(--content-background);

  &.promoter {
    .step-name.complete {
      .step {
        background: var(--primary-orange);
      }
    }
    .progress-divider.complete {
      .inner-color {
        width: 100%;
        background: var(--primary-orange);
      }
    }
  }

  &.planner {
    .step-name.complete {
      .step {
        background: var(--primary-green);
      }
    }
    .progress-divider.complete {
      .inner-color {
        width: 100%;
        background: var(--primary-green);
      }
    }
    .progress-divider {
      &.incomplete {
        background: linear-gradient(
          90deg,
          var(--primary-green) 10%,
          rgba(68, 182, 120, 0.0018601190476190688) 62%
        );
      }
    }
  }
  &.buyer {
    .step-name.complete {
      .step {
        background: var(--primary-golden);
      }
    }
    .progress-divider.complete {
      .inner-color {
        width: 100%;
        background: var(--primary-golden);
      }
    }
    .progress-divider {
      &.incomplete {
        background: linear-gradient(
          90deg,
          var(--primary-golden) 10%,
          rgba(68, 182, 120, 0.0018601190476190688) 62%
        );
      }
    }
  }
}
div.stepper-container {
  box-sizing: content-box;
  display: flex;
  position: relative;
  justify-content: space-evenly;
  padding: 10px 0 30px;
  width: 56%;
  margin: auto;
  max-width: 1000px;
  // position: ;
}
div.step-container {
  display: flex;
  flex: 1;
  opacity: 0.5;

  &.non-linear {
    opacity: 1;
  }
  &.firststep,
  &.laststep {
    flex: 0;
  }
  &.active {
    opacity: 1;
  }
}

.step-name {
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  cursor: pointer;
  .name {
    font-size: 14px;
    position: absolute;
    top: 40px;
    width: max-content;
  }
  .step {
    box-shadow: inset 0px 3px 5px -5px black, inset -3px -1px 5px -7px #fdfefd;
    cursor: pointer;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    border-radius: 50%;
    background: var(--dashboard-tiles);
    transition: background 1s ease-out;
  }

  &.complete {
    .step {
      color: white;
    }
  }
}
.progress-divider {
  flex: 1 1 auto;
  height: 8px;
  box-shadow: inset 0px 1px 3px -2px black, inset 0px -2px 3px -5px white;
  // background: #29acd9;
  align-self: center;
  background: var(--dashboard-tiles);

  .inner-color {
    transition: width 1.5s ease-out;
    width: 0;
    height: 100%;
  }

  &.complete {
    .inner-color {
      width: 100%;
    }
  }
}
.animation {
  position: absolute;
  justify-content: center;
  align-items: center;
  z-index: 0;
  width: 100px;
  height: 120%;
  right: -10px;
  top: 0;
  bottom: 0;
  display: none;
}

@media screen and (max-width: 930px) {
  div.stepper-container {
    width: 80%;
  }
}
@media screen and (max-width: 730px) {
  div.stepper-container {
    width: 75%;
  }
  div.step-container {
    .step-name {
      .step {
        width: 22px;
        height: 22px;
      }
      .name {
        font-size: 2vw;
      }
    }
  }
}
</style>
