var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tabs"},_vm._l((_vm.tabs),function(tab,index){return _c('div',{key:index,class:[
      {
        'tab-wrapper': true,
        active: _vm.isSelected(index),
        tabDisable: _vm.isTabDisabled(index),
      },
    ],on:{"click":function($event){return _vm.handleTabClick(index)}}},[_c('div',{staticClass:"tab-content"},[_vm._v(_vm._s(tab))])])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }