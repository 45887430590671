<template>
  <div class="footer">
    <div class="footer-inner">
      <div class="footer-segment">
        <div class="footer-logo">
          <img
            src="@/images/logos/Worre-transparent.png"
            v-if="theme === 'dark'"
            alt="incredevent.com"
          />
          <img
            src="@/images/logos/Worre-transparent.png"
            v-else
            alt="incredevent.com"
          />
        </div>
      </div>
      <div class="center-section">
        <div class="footer-segment">
          <h4>Assistance</h4>
          <a href="https://www.help.incredevent.com/faqs" target="_blank" v-if="$route.params.event !== 'herbalife-worldwide-workout-nevada-25045300912'">
            FAQ
          </a>
          <span @click="openChat">
            <a>
              Contact Us
            </a>
          </span>
        </div>
        <div class="footer-segment">
          <h4>Legal</h4>
          <span @click="closeMenu">
            <router-link to="/terms">
              Terms of Use
            </router-link>
          </span>
          <span @click="closeMenu">
            <router-link to="/privacy-policy">
              Privacy Policy
            </router-link>
          </span>
          <span @click="closeMenu">
            <router-link to="/refunds">
              Refunds &amp; Cancellations
            </router-link>
          </span>
          <span @click="closeMenu">
            <router-link to="/cookies">
              Cookies Policy
            </router-link>
          </span>
          <span @click="closeMenu">
            <router-link to="/security">
              Security
            </router-link>
          </span>
        </div>
      </div>

      <div class="footer-segment">
        <div v-if="$route.params.event == 'herbalife-worldwide-workout-nevada-25045300912'">
        </div>
        <div class="payment-logos" v-else>
          <div class="column1">
            <div class="payment-logo">
              <img src="@/images/icons/credit-card/visa.png" alt="visa logo" />
            </div>
            <div class="payment-logo">
              <img
                src="@/images/icons/credit-card/mastercard.png"
                alt="mastercard logo"
              />
            </div>
            <div class="payment-logo">
              <img src="@/images/icons/credit-card/amex.png" alt="amex logo" />
            </div>
          </div>

          <div class="column2">
            <div class="payment-logo">
              <img
                src="@/images/icons/credit-card/discover.png"
                alt="discover logo"
              />
            </div>
            <div class="payment-logo">
              <img
                src="@/images/icons/credit-card/union-pay.png"
                alt="union pay logo"
              />
            </div>
          </div>

          <div class="column3">
            <div class="payment-logo">
              <img
                src="@/images/icons/credit-card/visa-debit.png"
                alt="visa debit logo"
              />
            </div>
            <div class="payment-logo">
              <img
                src="@/images/icons/credit-card/mastercard-debit.png"
                alt="mastercard debit logo"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="copy">
      <span>© {{ currentYear }}. All Rights Reserved.&nbsp;</span>
      <span>
        Use of this website signifies your agreement to our
        <span @click="closeMenu">
          <router-link to="/cookies"> Cookies Policy,</router-link>
        </span>
        <span @click="closeMenu">
          <router-link to="/privacy-policy">
            Privacy Policy,
          </router-link>
        </span>
        and
        <span @click="closeMenu">
          <router-link to="/terms"> Terms of Use.</router-link>
        </span>

        <!--        <span-->
        <!--          >INCREDEVENT is a TM held by Sync Digital Solutions, Inc. and a-->
        <!--          partner with Worre Studios ticketing.</span-->
        <!--        >-->
      </span>
    </div>
    <!-- end footer inner -->
  </div>
</template>
<style lang="less" scoped>
.router-link-active {
  color: #fff;
}
.footer {
  * {
    font-family: "Roboto", sans-serif !important;
  }
  width: 100%;
  background: @colors[navbar-background];
  box-shadow: 1px 1px 2px -1px #ffffff26, inset 1px 1px 3px 0px #00000026;

  .footer-inner {
    display: flex;
    justify-content: space-between;
    margin: auto;
    padding: 30px 0;
    width: 94%;
    max-width: 1100px;

    .center-section {
      display: flex;
      justify-content: space-evenly;
      width: 100%;
    }
    .footer-segment {
      padding: 10px;
      h4 {
        font-size: 21px;
        margin-bottom: 8px;
        margin-top: 0;
        // margin-bottom: 0.5em;
      }

      a {
        display: block;
        font-size: 13px;
        color: var(--primary-golden);
        text-align: left;
        margin: 7px 0;
        word-break: break-word;
        // padding: ;

        &:hover {
          color: #fff;
        }
      }
      span {
        a {
          cursor: pointer;
        }
      }

      .footer-logo {
        width: 100px;
        img {
          display: block;
          max-width: 100%;
          width: 100%;
        }
      } // end footer-logo

      .payment-logos {
        // margin-top: 1em;
        display: flex;
        flex-direction: column;
        .column1 {
          display: flex;
        }
        .column2 {
          display: flex;
          justify-content: space-evenly;
        }
        .column3 {
          display: flex;
          justify-content: space-evenly;
        }
        .payment-logo {
          margin: 8px 0;
          width: 50px;
          .item-a {
            grid-area: header;
          }
          .item-b {
            grid-area: main;
          }
          .item-c {
            grid-area: t;
          }
          img {
            display: block;
            max-width: 100%;
          }
        }

        .column1 {
          display: flex;
        }
        .column2 {
          display: flex;
          justify-content: space-evenly;
        }
        .column3 {
          display: flex;
          justify-content: space-evenly;
        }
        .payment-logo.e {
          grid-column: 2;
        }
      }
    }
    @media screen and (min-width: 1200px) {
      max-width: 1100px;
    }

    @media screen and (max-width: 800px) {
      flex-wrap: wrap;
      justify-content: center;

      .center-section {
        justify-content: space-between;
      }

      .footer-segment {
        h4 {
          font-size: 17px;
        }
        .payment-logos {
          // grid-template-rows: 1fr;
          display: flex;
          align-items: center;
          justify-content: space-around;
          flex-direction: row;
          grid-column-gap: 0;
          .payment-logo {
            margin: 0 5px;
            width: 40px;
          }
        }
      }
    }
  } // end footer-inner

  .copy {
    padding: 20px;
    display: flex;
    justify-content: center;
    font-size: 12px;
    border-top: 1px solid #41454d;
    // box-shadow: inset 1px 0px 10px -5px black;
    background: @colors[navbar-background];
    // color: #697e8a;
    flex-wrap: wrap;

    a {
      color: var(--primary-golden);
      display: contents;
    }
    span {
      text-align: center;
    }
  }

  @media screen and (max-width: 730px) {
    padding-bottom: 90px;
    display: none;
  }
} // end footer
</style>
<script>
export default {
  name: "Footer",
  methods: {
    closeMenu() {
      this.$emit("closeMenu");
    },
    openChat() {
      Beacon("open");
    },
  },
  computed: {
    theme() {
      return this.$store.state.theme;
    },
    currentSite() {
      return this.$store.state.site;
    },
    buyers() {
      switch (process.env.VUE_APP_ENVIRONMENT) {
        case "development":
          return "http://localhost:3100/events";
        case "staging":
          return "https://staging.incredevent.com/events";
        default:
          return "https://incredevent.com/events";
      }
    },
    covid() {
      switch (process.env.VUE_APP_ENVIRONMENT) {
        case "development":
          return "http://localhost:3200/covid-relief";
        case "staging":
          return "https://planners.staging.incredevent.com/covid-relief";
        default:
          return "https://planners.incredevent.com/covid-relief";
      }
    },
    user() {
      return this.$store.state.user;
    },
    currentYear() {
      let date = new Date();
      return date.getFullYear();
    },
  },
};
</script>
