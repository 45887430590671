<template>
  <modal title="Type of Contact" :active="active" v-on:close="close">
    <div class="top">
      <p class="center down" v-if="brand">
        Questions pertaining to the brand itself such as the future events,
        production, etc.
      </p>
      <p class="center down" v-else>
        Questions pertaining to the event itself such as the production,
        itinerary, talent, etc.
      </p>
      <FormulateInput
        class="checkout-back center"
        type="button"
        name="BRAND QUESTIONS"
        @click="eventQuestion"
        v-if="brand"
      />
      <FormulateInput
        class="checkout-back center"
        type="button"
        name="EVENT QUESTIONS"
        @click="eventQuestion"
        v-else
      />
      <p class="center down">
        Questions pertaining to your purchase, receipts, clarification on
        transactions, or fees.
      </p>
      <FormulateInput
        class="checkout-back center"
        type="button"
        name="TICKETING/ACCESS QUESTIONS"
        @click="ticketQuestion"
      />

    </div>
  </modal>
</template>

<script>
import Modal from "@/components/Modal.vue";

export default {
  name: "contact-modal",
  components: {
    Modal,
  },
  props: {
    url: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    brand: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      active: false,
    };
  },
  methods: {
    open() {
      this.active = true;
    },
    close() {
      this.active = false;
    },
    eventQuestion() {
      this.close();
      this.$bus.$emit("contact-coordinator-no-option", this.url, this.name);
    },

    ticketQuestion() {
      this.close();
      Beacon('open');
    }
  }
};
</script>

<style lang="less" scoped>
.modal {
  .modal-inner {
    .modal-dialog {
      .modal-body {
        .modal-content {
          .error-label {
            margin-bottom: 0.75em;
            color: #e60022;
            text-align: center;
            font-weight: 600;
          }

          .title {
            margin-top: 0 !important;
          }

          .top {
            margin-top: 20px;
          }

          .down {
            margin-bottom: 20px;
          }

          .center {
            text-align: center;
            display: flex;
            justify-content: center;
          }
        }
      }
    }
  }
}
</style>