<template>
  <div>
    <alert-modal ref="alert" />
    <modal
      class="alert"
      :active="active"
      :title="title"
      :confirm="confirm"
      :closable="closable"
      v-on:close="close"
    >
      <ShareLinks :event="event" />
    </modal>
  </div>
</template>

<script>
import Modal from "@/components/Modal.vue";
import DiyoboButton from "@/components/DiyoboButton.vue";
import AlertModal from "@/components/modals/AlertModal.vue";
import ShareLinks from "../events/ShareLinks";

export default {
  name: "ticket-options",
  components: {
    Modal,
    DiyoboButton,
    AlertModal,
    ShareLinks
  },
  props: {
    event: Object
  },
  data() {
    return {
      title: "",
      confirm: "Okay",
      active: false,
      onConfirm: null,
      closable: true,
      contactType: "",
      formValues: {
        name: null,
        email: null,
        message: null,
        url: ""
      }
    };
  },
  methods: {
    open(args) {
      this.active = true;
      this.title = args.title || "Heads Up";
      this.confirm = args.confirm || "Send";
      this.onConfirm = args.onConfirm;
      this.closable = !!args.closable;
      this.contactType = args.contactType || "tickets";
      this.url = args.url || "";
      document.body.style.overflow = "hidden";
    },
    close() {
      this.active = false;
    },
    okay() {
      if (this.onConfirm) this.onConfirm();
      if (this.contactType === "tickets") {
        this.contactTickettote(
          this.url,
          this.formValues.name,
          this.formValues.email,
          this.formValues.message
        );
        this.$refs.alert.open({
          title: "Success!",
          msg: "Your message has been sent."
        });
      } else {
        this.contactCoordinator(
          this.url,
          this.formValues.name,
          this.formValues.email,
          this.formValues.message
        );
        this.$refs.alert.open({
          title: "Success!",
          msg: "Your message has been sent."
        });
      }
      this.$formulate.reset("contactForm");

      this.close();
    },
    contactCoordinator(url, name, email, message) {
      this.$axios
        .post("/emails/contact-planner", {
          url,
          name,
          email,
          message
        })
        .then(response => {
          if (response.error) {
            this.ccmodalE = response.error;
          } else {
            this.ccmodal = false;
            this.$refs.alert.open({
              title: "Success!",
              msg: "Your message has been sent."
            });
          }
        })
        .catch(e => {
          this.ccmodalE = e;
        });
    },
    contactTickettote(url, name, email, message) {
      this.$axios
        .post("/emails/contact-ticketquestions", {
          url,
          name,
          email,
          message
        })
        .then(response => {
          if (response.error) {
            this.ccmodalE = response.error;
          } else {
            this.ccmodal = false;
            this.$refs.alert.open({
              title: "Success!",
              msg: "Your message has been sent."
            });
          }
        })
        .catch(e => {
          this.ccmodalE = e;
        });
    }
  }
};
</script>

<style lang="less" scoped>
.alert {
  z-index: 1001;

  .modal-footer {
    button {
      margin-left: 16px;
    }
  }
}

.buttons {
  display: inline-flex;
  justify-content: space-between;
  .action-btns {
    .button {
      margin-left: 20px;
    }
  }
}
</style>
