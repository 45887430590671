<template>
  <div class="info-text-container">
    <div class="info-text-inner">
      <div class="icon" v-if="icon">
        <font-awesome-icon icon="info-circle" />
      </div>
      <div class="info-left">
        <span class="header">{{ header }}</span>
        <slot class="slot"></slot>
      </div>
      <div v-if="buttonName" class="info-right">
        <DiyoboButton
          :txt="buttonName"
          :type="site === 'buyer' ? 'secondary' : 'primary'"
          @click="$router.push(link)"
        />
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.info-text-container {
  display: flex;
  justify-content: space-between;
  border-radius: 8px;
  border: 1px solid var(--primary-golden);
  padding: 16px;
  // width: 100%;
  overflow: hidden;
  margin-bottom: 16px;

  @media screen and (max-width: 850px) {
    max-height: 1000px;
  }

  .icon {
    color: #53b85a;
    padding-right: 15px;
    font-size: 3em;
  }

  .info-text-inner {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .info-left {
      display: flex;
      flex-direction: column;
      span {
        color: var(--primary-golden);
        font-size: 1em;
        font-weight: bold;
      }
      ul {
        margin: 1em 0;
        padding: 0 0 0 20px;
      }
      p {
        font-size: 1em;
      }
    }
    .info-right {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import DiyoboButton from "@/components/DiyoboButton.vue";

library.add(faInfoCircle);

export default {
  name: "info-text",
  components: {
    FontAwesomeIcon,
    DiyoboButton,
  },
  props: {
    icon: {
      type: Boolean,
      default: false,
    },
    itemList: {
      type: String,
      default: "",
    },
    header: String,
    buttonName: String,
    site: String,
    link: String,
  },
};
</script>
