<template>
  <div id="app">
    <!-- <transition name="default" mode="out-in"> -->
    <!-- TODO: is there a way we can only import this into pages that need it? -->
    <!-- <script src="https://maps.googleapis.com/maps/api/js?key=AIzaSyBS8YTbxBviNQGQJsPL-_aCBgpayzOPB20&libraries=places"></script> -->
    <!-- Start of worre Zendesk Widget script -->
    <!--    <script-->
    <!--      id="ze-snippet"-->
    <!--      src="https://static.zdassets.com/ekr/snippet.js?key=108c3dac-a40c-4bc0-9b1f-b2142c5fa185"-->
    <!--    ></script>-->
    <!--    &lt;!&ndash; End of worre Zendesk Widget script &ndash;&gt;-->

    <!--    <script src="https://mccdn.me/assets/js/widget.js" defer="defer"></script>-->

    <!-- Modals -->
    <alert-modal ref="alert" />
    <!-- confirm -->
    <confirm-modal ref="confirm" />

    <forgot-modal
      v-if="!user.isAuthenticated"
      :active="fmodal"
      :error="fmodalE"
      v-on:confirm="forgotConfirm"
      v-on:dismiss="dismiss('fmodal')"
    />
    <!--  -->
    <change-password-modal
      v-if="!user.isAuthenticated"
      :active="cpmodal"
      :error="cpmodalE"
      v-on:confirm="changePassword"
      v-on:dismiss="dismiss('cpmodal')"
    />
    <!--  -->
    <contact-coordinator-modal
      :active="ccmodal"
      :event="ccevent"
      :title="ccname"
      :error="ccmodalE"
      :show-option="showOption"
      v-on:dismiss="dismiss('ccmodal')"
      v-on:confirmTickettote="contactTickettote"
      v-on:confirmPlanner="contactCoordinator"
    />
    <loading-modal />
    <diyobo-name-update-modal ref="diyoboNameUpdateModal" />
    <steppers
      :steps="stepsCom"
      class="buyer"
      v-if="!isStepper"
      @stepperComplete="stepperComplete"
    />

    <upper-navbar
      :class="{ stepper: !isStepper }"
      :account-links="accountItems"
      v-on:login="toLogin"
      v-on:register="loadModal('rmodal')"
      v-on:logout="logout"
    />

    <navbar
      textless-logo
      :brandName="brandName"
      site="buyers"
      :class="{ stepper: !isStepper }"
      home-page="/"
      :items="navItems"
      :account-links="accountItems"
      v-on:login="toLogin"
      v-on:register="loadModal('rmodal')"
      v-on:logout="logout"
    />
    <!-- v-if="" -->
    <!--    <Nuxt class="main-cont" :class="{ stepper: !isStepper }" />-->
    <!-- <embed src="" type="application/pdf" id="pdf" /> -->
    <transition name="fade" mode="out-in">
      <router-view class="main-cont" :class="{ stepper: !isStepper }" />
    </transition>
    <div v-if="!authenticated" id="Loading">
      <div id="Header">
        <div class="header-inner"></div>
      </div>
      <div id="Content">
        <div class="content-inner"></div>
      </div>
    </div>
    <Footer v-on:logout="logout"></Footer>
    <!-- <footer /> -->
    <!-- </transition> -->

    <!-- end footer -->
    <!--    <client-only>-->
    <!--      <script>-->
    <!--        (function (d) {-->
    <!--          var s = d.createElement("script");-->
    <!--          s.setAttribute("data-account", "ADbBFJvTvP");-->
    <!--          s.setAttribute("src", "https://cdn.userway.org/widget.js");-->
    <!--          (d.body || d.head).appendChild(s);-->
    <!--        })(document);-->
    <!--      </script>-->
    <!--      <noscript>-->
    <!--        Please ensure Javascript is enabled for purposes of-->
    <!--        <a href="https://userway.org">website accessibility</a>-->
    <!--      </noscript>-->
    <!--    </client-only>-->
  </div>
</template>

<script>
import AlertModal from "@/components/modals/AlertModal.vue";
import ChangePasswordModal from "@/components/modals/ChangePasswordModal.vue";
import ForgotModal from "@/components/modals/ForgotModal.vue";
import LoadingModal from "@/components/modals/LoadingModal.vue";
import Steppers from "@/components/Steppers.vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import UnreadChats from "../components/dconnect/UnreadChats.vue";
import ConfirmModal from "../components/modals/ConfirmModal.vue";
import ContactCoordinatorModal from "../components/modals/ContactCoordinatorModal.vue";
import LoginModal from "../components/modals/LoginModal.vue";
import RegisterModal from "../components/modals/RegisterModal.vue";
import {
  createSession,
  destroySession,
  getSessionCookie,
  SESSION_COOKIE,
} from "../helpers/auth.js";
// import { Connection } from 'autobahn'
import DiyoboInput from "@/components/DiyoboInput.vue";
import Footer from "@/components/Footer.vue";
import Loader from "@/components/Loader.vue";
import DiyoboNameUpdateModal from "@/components/modals/DiyoboNameUpdateModal.vue";
import Navbar from "@/components/Navbar.vue";
import SiteSwitcher from "@/components/SiteSwitcher.vue";
import Tote from "@/components/Tote.vue";
import UpperNavbar from "@/components/UpperNavbar.vue";
import ModalContent from "@/helpers/modals";
import { getThemeCookie } from "@/helpers/theme";
import SiteWideBanner from "../components/SiteWideBanner.vue";

library.add(faBars);

export default {
  name: "app",
  components: {
    Steppers,
    LoginModal,
    RegisterModal,
    ForgotModal,
    AlertModal,
    ConfirmModal,
    ChangePasswordModal,
    ContactCoordinatorModal,
    UnreadChats,
    FontAwesomeIcon,
    DiyoboInput,
    SiteSwitcher,
    Tote,
    Loader,
    LoadingModal,
    Navbar,
    SiteWideBanner,
    Footer,
    DiyoboNameUpdateModal,
    UpperNavbar,
  },
  head() {
    return {
      title: "",
      script: [],
      titleTemplate: (title) => (title ? `${title} • Worre` : "Worre"),
    };
  },
  data() {
    return {
      brandName: "",
      // theme: "light",
      publicPath: process.env.BASE_URL,

      navItems: [],
      accountItems: [
        {
          header: "Account",
          links: [
            { name: "Profile", link: "/profile" },
            { name: "My Purchases", link: "/my-tickets" },
            { name: "Manage Tickets", link: "" },
          ],
        },
      ],
      steps: [
        {
          number: 1,
          name: "Register",
          complete: true,
          active: false,
        },
        {
          number: 2,
          name: "Complete Profile",
          complete: false,
          active: false,
          link: "/profile",
        },

        {
          number: 3,
          name: "First Purchase",
          complete: false,
          active: false,
          link: "/",
        },
        {
          number: 4,
          name: "Complete",
          complete: false,
          active: false,
        },
      ],
      hideStepper: true,

      auth: false,
      lmodal: false,
      lmodalE: null,
      rmodal: false,
      socket: undefined,
      amodal: false,
      test: "Will this work?",
      dropdownOptions: [
        { label: "Reset Password", value: "Reset Password" },
        { label: "Log Out" },
      ],
      fmodal: false,
      fmodalE: null,
      cpmodal: false,
      cpmodalE: null,
      ccmodal: false,
      showOption: true,
      ccevent: "",
      ccname: "",
      ccmodalE: null,
      unreadChats: 0,
      profileImg: "",
      authenticated: false,
      firstName: "",
    };
  },
  computed: {
    // theme() {
    //   return this.$store.state.theme;
    // },
    session_id: {
      get() {
        return this.$store.state.session_id;
      },
      set(id) {
        this.$store.state.session_id = id;
        return id;
      },
    },
    isStepper() {
      return true;
      let user = this.$store.state.user;
      user.hideBuyerStepper = user.hideBuyerStepper || false;
      if (user.isAuthenticated && !user.hideBuyerStepper) {
        this.hideStepper = false;
      } else {
        this.hideStepper = true;
      }
      return this.hideStepper;
    },
    stepsCom() {
      let user = this.$store.state.user;

      if (user.profile_complete) {
        this.steps[1].complete = true;
      }
      if (user.upcomingTickets > 0) {
        this.steps[2].complete = true;
      }
      if (this.steps[1].complete && this.steps[2].complete) {
        setTimeout(() => {
          // this.steps[3].complete = true;
          this.steps[3].complete = true;
        }, 2000);

        // setTimeout(() => (steps[3].complete = true), 2000);
      } else {
        this.steps[3].complete = false;
      }

      return this.steps;
    },
    user() {
      return this.$store.state.user;
    },
    embedPage() {
      if (this.$route.name === "event-guestlist-embed") {
        return true;
      } else {
        return false;
      }
    },
  },
  // This ran the middleware auth on every peage. Not sure why it's needed.
  // middleware: ["auth"],

  async mounted() {
    this.$store.commit("setTheme", getThemeCookie());
    let q = this.$route.query;
    if (q.redirect) {
      this.$router.push(q.redirect);
    }
    const notificationsUrl =
      process.env.VUE_APP_NOTIFICATIONS_SOCKET +
      "?session_id=" +
      this.$store.state.session_id;

    if (this.user.isAuthenticated) {
      this.$notifications.connect(notificationsUrl);
      this.$notifications.init("buyers");

      if (
        this.user.diyobo &&
        this.user.first_name === "Your" &&
        this.user.last_name === "Name"
      ) {
        this.$root.$emit("show-diyobo-name-update-modal");
      }
    }
    this.$bus.$on("contact-coordinator", (url, name) => {
      this.ccmodal = true;
      this.ccevent = url;
      this.ccname = name;
    });

    this.$bus.$on("contact-coordinator-no-option", (url, name) => {
      this.ccmodal = true;
      this.ccevent = url;
      this.ccname = name;
      this.showOption = false;
    });
    if (this.user.isAuthenticated) {
      try {
        const { data } = await this.$axios.post(
          "/toteswap/fetch-planner-policy"
        );

        if (data.enableUpgrade || data.enableExchange || data.enableRefund) {
          this.accountItems[0].links[2].enablePolicy = true;
          if (data.enableUpgrade) {
            this.accountItems[0].links[2].link = "/tote-swap";
          } else if (data.enableExchange) {
            this.accountItems[0].links[2].link = "/tote-swap/exchange";
          } else {
            this.accountItems[0].links[2].link = "/tote-swap/refund";
          }
        } else {
          this.accountItems[0].links[2].enablePolicy = false;
        }
      } catch (e) {
        console.log(e);
      }
    }
  },
  watch: {
    // Wooooo this suuuucks lets GOOOOOOOOOO
    // $route(to) {
    //   // get query string
    //   let q = to.query;
    //   if (q.redirect) {
    //     this.toggleLogin();
    //   }
    //   if (q.forgot_password_key) {
    //     console.log(q, q.forgot_password_key);
    //     this.cpmodal = true;
    //   }
    // },
  },
  methods: {
    setBrandName(data) {
      this.brandName = data;
    },
    stepperComplete() {
      this.$axios
        .post("/account/update-stepper", { site: "buyer" })
        .then(({ data }) => {
          if (data.success) {
            this.hideStepper = true;
            this.$store.commit("updateUserProperty", {
              property: "hideBuyerStepper",
              value: true,
            });
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    // changeTheme() {
    //   this.$store.dispatch("toggleTheme");

    //   if (this.user.isAuthenticated) {
    //     this.$axios.post("/account/update-theme", {
    //       theme: this.theme
    //     });
    //   }
    // },
    logout: async function () {
      let self = this;
      if (this.user.isAuthenticated) {
        sessionStorage.clear();
        destroySession(self);
        self.$notifications.disconnect();
        // self.$store.state.chat.connection.close('com.diyobo.chat.logout', `${self.user.name} left the realm.`)

        if (self.$route.name == "event") {
          window.location.reload(true);
        } else if (self.$route.name == "event-checkout") {
          self.$router.push("/" + self.$route.params.event);
        } else if (!self.$route.meta.allowAnonymous) {
          self.$router.push("/");
        }

        if ($cookies.isKey("currency")) {
          $cookies.remove("currency");
        }

        self.$refs.alert.open(ModalContent.logout);
      } else {
        let loginInfo = self.$refs.loginModal;

        // try {
        //   await createSession(loginInfo.name, loginInfo.password, self);
        //   // this.$store.state.chat.connection.open()
        //   this.lmodalE = null;
        // } catch (e) {
        //   this.lmodalE = e;
        // }

        let q = self.$route.query;
        if (q.redirect) {
          self.$router.push(q.redirect);
        } else if (self.$store.state.user.phone == null) {
          self.$router.push("/profile");
        }

        let name = self.$store.state.user.name.split(" ");
        self.firstName = name[0];
      }
    },
    // end login
    toLogin() {
      this.$router.push("/");
    },
    loadModal(val) {
      this[val] = true;
    },
    dismiss(val) {
      this[val] = false;

      if (val == "amodal") {
        this.rmodal = false;
      }
      this.showOption = true;
      document.getElementsByTagName("body")[0].style.overflow = "visible";
    },
    signupErr() {
      // alert('you messed up BOI')
    },
    register() {
      this.lmodal = false;
      this.rmodal = true;
    },
    toggle() {
      let nav = document.getElementById("nav-inner");
      let icon = document.getElementById("icon");

      if (nav.className == "nav-inner") {
        nav.className += " responsive";
        icon.className += " responsive";
      } else {
        nav.className = "nav-inner";
        icon.className += "icon";
      }
    },
    toggleMenu() {
      let menu = document.getElementById("options-wrapper");

      if (menu.classList.contains("collapsed")) {
        menu.classList.remove("collapsed");
      } else if (!menu.classList.contains("collapsed")) {
        menu.classList.add("collapsed");
      }
    },
    forgot() {
      this.lmodal = false;
      this.fmodal = true;
    },
    forgotConfirm(email) {
      this.$axios
        .post("/account/forgot-password", { email })
        .then((response) => {
          this.fmodal = false;
          this.$refs.alert.open(ModalContent.forgotPasswordAfter);
        })
        .catch((e) => {
          this.fmodalE = e;
        });
    },
    changePassword(password, confirmPassword) {
      this.$axios
        .post("/account/change-password", {
          key: this.$route.query.forgot_password_key,
          password,
          confirmPassword,
        })
        .then((response) => {
          let data = response.data;
          if (data.error) {
            this.cpmodalE = data.error;
          } else {
            if (data[0] && data[1] && data[2]) {
              this.cpmodal = false;
              if (data[3]) {
                const content = ModalContent.changePasswordSame;
                this.$refs.alert.open({
                  title: content.title,
                  msg: content.msg,
                  confirm: content.confirm,
                  onConfirm: () => {
                    this.$bus.$emit("login");
                  },
                });
              } else {
                const content = ModalContent.changePasswordSuccess;
                this.$refs.alert.open({
                  title: content.title,
                  msg: content.msg,
                  confirm: content.confirm,
                  onConfirm: () => {
                    this.$bus.$emit("login");
                  },
                });
              }
            } else {
              this.cpmodalE = "There was an error changing your password.";
            }
          }
        })
        .catch((e) => {
          this.cpmodalE = e;
        });
    },
    contactCoordinator(url, name, email, message) {
      this.$axios
        .post("/emails/contact-planner", {
          url,
          name,
          email,
          message,
        })
        .then((response) => {
          if (response.error) {
            this.ccmodalE = response.error;
          } else {
            this.ccmodal = false;
            this.$refs.alert.open({
              title: "Success!",
              msg: "Your message has been sent.",
            });
          }
        })
        .catch((e) => {
          this.ccmodalE = e;
        });
    },
    contactTickettote(url, name, email, message) {
      this.$axios
        .post("/emails/contact-ticketquestions", {
          url,
          name,
          email,
          message,
        })
        .then((response) => {
          if (response.error) {
            this.ccmodalE = response.error;
          } else {
            this.ccmodal = false;
            this.$refs.alert.open({
              title: "Success!",
              msg: "Your message has been sent.",
            });
          }
        })
        .catch((e) => {
          this.ccmodalE = e;
        });
    },
  },
};
</script>
<style lang="less">
.page-enter-active,
.page-leave-active {
  transition: opacity 0.3s;
}

.page-enter,
.page-leave-active {
  opacity: 0;
}

@import "https://use.typekit.net/mqb5euw.css";
@import (css)
  url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap");
@import (css)
  url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;500&display=swap");
@import (css)
  url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

// init css variables for colors
.init-color-vars();
* {
  font-family: "Open Sans", sans-serif;
  -webkit-tap-highlight-color: transparent;
  // box-sizing: initial !important;
}

#app.embed-leads-form {
  * {
    font-family: "Poppins", sans-serif !important;
  }

  .formulate-input .formulate-input-label {
    font-weight: 400 !important;
  }

  // .formulate-input[data-type="button"] {
  //   width: fit-content !important;
  // }

  .content-inner {
    padding-bottom: 15px;
  }

  input {
    &:focus {
      border: 1px solid #6f00ff !important;
    }
  }

  .submit-btn {
    button {
      background: #6f00ff !important;
      border-radius: 50px !important;
      font-weight: bold !important;
      padding: 12px 47px !important;
      border: 2px solid #6f00ff !important;
      transition: all 0.2s ease-in-out 0s;
      margin: auto;

      .loader {
        border: 2px solid white;
        border-top: 2px solid transparent;
      }

      &:hover {
        background: white !important;
        color: #6f00ff !important;
      }
    }
  }

  @media screen and (max-width: 600px) {
    input,
    button,
    label {
      font-size: 12px;
    }
  }
}

:root {
  --primary: var(--primary-golden);
  --secondary: var(--secondary-golden);
}

a {
  color: var(--primary-golden);
}

// Animations
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

// end Animations

html,
body,
#__nuxt,
#__layout {
  margin: 0;
  padding: 0;
  height: 100%;
}

body.menu-open {
  overflow: hidden;
}

h1,
h2,
h3,
h4 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  color: var(--primary-golden);
  margin: 20px 0;
}

// button.primary {
//   background: var(--secondary-golden) !important;
// }
h1 {
  font-size: 48px;
  font-weight: 700;
}

img {
  user-select: none;
}

@media only screen and (max-width: 500px) {
  h1 {
    font-size: 9vw;
  }
}

h2 {
  font-size: 30px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 20px;
}

.b1,
.b2,
.b3 {
  // font-weight: 200;
  color: var(--secondary-bluetext);
}

.b1 {
  font-size: 16px;
}

@media only screen and (max-width: 500px) {
  .b1 {
    font-size: 13px;
  }
}

.b2 {
  font-size: 14px;
}

.b3 {
  font-size: 12px;
}

p {
  font-size: 14px;
  // line-height: 160%;
  display: inline-block;
  margin: 10px 0;
  color: var(--text);
}

.light-bold {
  font-weight: 600;
}

.green {
  color: var(--primary-green);
}

@media screen and (max-width: 600px) {
  p {
    line-height: 180%;
  }
}

a {
  text-decoration: none;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--text);
  background-color: var(--background);
  // overflow-x: hidden;
}

.main-cont.stepper {
  margin-top: 75px;
}

#Header {
  // height: 165px;
  background-image: url("@/images/TT_SubHeader_BG.jpg");
  background-position: center;
  // background-size: contain;
  .header-inner {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    margin: auto;
    width: 94%;
    max-width: 1100px;
    height: 100%;

    .page-title {
      display: flex;
      margin: 10px auto;

      h1 {
        font-size: 2.25em;
      }

      h2 {
        margin-bottom: 1.25em;
        margin-left: 0.4em;
        font-size: 30px;
        font-weight: 400;
        color: var(--subtitle);
      }
    }

    // end page-title
  }

  // end header-inner
}

// end Header

#app.light #Header {
  background-image: none;
  background: var(--primary-golden);

  .header-inner .page-title h1 {
    color: white;
  }
}

.main-cont.stepper {
  margin-top: 72px;
}

#Content {
  position: relative;
  // z-index: 10;
  //min-height: 50vh;
  background-color: var(--content-background);

  // .shadow {
  //   position: absolute;
  //   top: 0;
  //   bottom: 0;
  //   // left: -25%;
  //   width: 100%;
  //   height: 100%;
  //   box-shadow: inset 0 0 20px 0px @colors[content-shadow];
  // }

  .content-inner {
    margin: auto;
    padding-top: 15px;
    padding-bottom: 100px;
    position: relative;
    width: 92%;
    max-width: 1100px;
    overflow: visible;
    // height: 60vh; //change

    section {
      margin-bottom: 2em;
    }

    // end section

    .divider {
      margin: 1em auto;
      width: 100%;
      border-bottom: 1px solid var(--divider);
    }

    .content-path {
      color: var(--breadcrumbs);
      padding-bottom: 15px;

      a {
        color: var(--breadcrumbs);
      }
    }

    .events {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      align-items: flex-start;
      margin-left: -0.7533%;
      //   padding: 2em 0;
      width: 101.5066%;
    }

    .row {
      margin: 0;
      display: flex;
      flex-wrap: wrap;
      // margin-left: -0.5em;
      // width: calc(100% + 1em);
      width: 100%;
      justify-content: space-between;

      .col {
        flex-basis: initial;
        // flex-grow: initial;
        padding: 0;

        &.flex {
          display: flex;
          margin: auto;
        }

        &.center {
          justify-content: center;
        }

        &.full {
          width: 100%;
        }

        // end full col

        &.half {
          width: calc(50% - 10px);
        }

        // end half col

        &.quarter {
          width: calc(25% - 10px);
        }

        // end 1/4 col

        &.third {
          width: calc(33.33% - 10px);
        }

        &.quarterx3 {
          width: calc(75% - 10px);
        }

        // end 3/4 col

        &.eighth {
          width: calc(12.5% - 10px);
        }
      }

      // end col
    }

    // end row
  }

  // end content-inner

  @media screen and (max-width: 600px) {
    .content-inner {
      width: 90%;
    }
  }
}

@media screen and (max-width: 620px) {
  #Content {
    min-height: calc(100vh - 153px);
  }
}

// .footer {
//   width: 100%;
//   background-color: @colors[footer-background];
//   color: white;

//   .footer-inner {
//     display: flex;
//     justify-content: space-between;
//     margin: auto;
//     padding: 20px 0 30px;
//     // padding-top: 10px;
//     // width: 94%;
//     // max-width: 94%;

//     .center-section {
//       display: flex;
//       justify-content: space-evenly;
//       width: 100%;
//     }
//     .footer-segment {
//       padding: 10px;
//       h4 {
//         // margin-top: 0.75em;
//         // margin-bottom: 0.5em;
//       }

//       a {
//         display: block;
//         font-size: 14px;
//         color: @colors[text];
//         text-align: left;
//         margin: 10px 0;
//         word-break: break-word;
//         // padding: ;

//         &:hover {
//           color: #30adc9;
//         }
//       }

//       .footer-logo {
//         margin-top: -1px;
//         width: 200px;

//         img {
//           display: block;
//           max-width: 100%;
//         }
//       } // end footer-logo

//       .payment-logos {
//         margin-top: 1em;
//         display: flex;
//         flex-direction: column;

//         .payment-logo {
//           margin: 8px 0;
//           width: 65px;

//           img {
//             display: block;
//             max-width: 100%;
//           }
//         }
//       } // end payment-logos

//       // @media screen and (max-width: 540px) {
//       //   padding: ;
//       //   // margin: auto;
//       //   // width: 96%;
//       // }
//     } // end footer-segment
//     @media screen and (min-width: 1200px) {
//       max-width: 1100px;
//     }
//     @media screen and (max-width: 1200px) {
//       max-width: 94%;
//     }
//     @media screen and (max-width: 800px) {
//       flex-wrap: wrap;
//       justify-content: center;

//       .center-section {
//         justify-content: space-between;
//       }

//       .footer-segment {
//         .payment-logos {
//           display: flex;
//           align-items: center;
//           flex-direction: row;
//           .payment-logo {
//             margin: 0 5px;
//           }
//         }
//       }
//     }
//   } // end footer-inner
// } // end footer

// media queries
// min 790px
@media screen and (min-width: 790px) {
  .page-title {
    display: flex;
    align-items: baseline;

    h2 {
      margin-left: 0.5em;

      &::before {
        margin-right: 0.25em;
        content: "-";
      }
    }
  }
}

// max 789px
@media screen and (max-width: 789px) {
  .page-title {
    h1 {
      margin: 0;
    }

    h2 {
      margin: 0;
      margin-bottom: 0.5em;
    }
  }
}

// max 749px
@media screen and (max-width: 749px) {
  #Content {
    .content-inner {
      .row {
        .col {
          &.half,
          &.quarter,
          &.third,
          &.quarterx3 {
            width: 100%;
          }

          // end half-col
        }

        // end col
      }

      // end row
    }

    // end content-inner
  }

  // end content
}

// end 749px breakpoint

@media screen and (max-width: 600px) {
  #Header {
    .header-inner {
      .page-title {
        h1 {
          font-size: 1.75em;
        }

        h2 {
          // margin-bottom: 0.75em;
          font-size: 1.5em;
        }
      }
    }
  }
}

@media screen and (max-width: 450px) {
  #Header {
    .header-inner {
      .page-title {
        h1 {
          font-size: 1.25em;
        }
      }
    }
  }
}

// @media screen and (min-width: 450px) {
//   #Header {
//     .header-inner {
//       .page-title {
//         h1 {
//           font-size: 2.25em;
//         }

//         h2 {
//           margin-bottom: 0.75em;
//           font-size: 1.5em;
//         }
//       }
//     }
//   }
// }
.col {
  flex-basis: initial !important;
  // flex-grow: unset !important;
}
</style>
