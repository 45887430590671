// This file contains the modal contents for alert modals

const ModalContent = {
  // global
  myTotePopup: {
    title: "Notice",
    msg:
      "Please note that tickets will be available in myTote and will not be sent via email",
    confirm: "Okay",
  },
  logout: {
    title: "Sad to See You Go 😢",
    msg: "You are now logged out. Hope to see you soon. We'll miss you.",
    confirm: "Bye!",
  },
  registerAfter: {
    title: "Look at You Go...",
    msg:
      "You're almost there! Just check your email to confirm your account and then you'll be an official member of WORRE STUDIOS. Welcome to the club!",
    confirm: "Okay",
  },
  forgotPassword: {
    title: "Forgot Password",
    msg:
      "So, you forgot your password, huh? No worries, it happens to the best of us. Just tell us which email you use to login to your account and we'll make sure you get access quickly!",
    confirm: "Submit",
  },
  forgotPasswordAfter: {
    title: "One More Step ...",
    msg: "We've got this! Check your email for further instructions.",
    confirm: "Right On!",
  },
  copyToClipboardError: {
    msg:
      "There was an error saving the link to the clipboard. Try copying the link manually.",
  },
  accountActivated: {
    title: "And Here. We. Go.",
    msg:
      "Your WORRE STUDIOS account is now active. Just login and you'll be able to buy tickets to your favorite events.",
    confirm: "Okay",
  },
  accountActivatedToProfile: {
    title: "And Here. We. Go.",
    msg: "Your WORRE STUDIOS account is now active.",
    confirm: "Okay",
  },
  accountActivationFailed: {
    msg: "Account activation failed, Please try again later.",
  },
  accountNotActivated: {
    // title: "And Here. We. Go.",
    msg: "Account hasn't been activated yet.",
  },
  contactSent: {
    msg: "Your message has been sent to the event coordinator.",
  },
  ticketQuestions: {
    msg: "Your message has been sent to our support staff.",
  },
  contactSuccess: {
    title: "Your Message Has Been Sent",
    msg:
      "We typically respond to emails within 48 hours but in many cases, much sooner. Hang tight, we've got you!",
  },
  weddingSuccess: {
    title: "Your Wedding Social is Pre-Booked!",
    msg: "We will email/call you when we are ready to do wedding socials.",
  },
  changePasswordSuccess: {
    title: "Congrats!",
    msg:
      "Now that’s change we can believe in. Your password has been successfully changed.",
    confirm: "Login",
  },
  changePasswordSame: {
    title: "Good Memory ... Kinda",
    msg:
      "Looks like there's been a glitch in The Matrix. You've tried to change your password to what we have listed as your current password. So, just proceed to login without any issues.",
    confirm: "Login",
  },
  incorrectLogin: {
    title: "Those creds ain't it chief!",
    msg:
      "Looks like you used the wrong password. Try again or reset your password.",
    confirm: "O-to-the-K",
  },
  accountLocked: {
    title: "Account Locked",
    msg:
      "Due to unusual activity, your account has been locked. Please contact an administrator for more details.",
    confirm: "O-to-the-K",
  },

  updateProfile: {
    title: "You Rock!",
    msg:
      "Your profile has been successfully updated and your INCREDEVENT experience will be a breeze as a result. 👊",
    confirm: "👊",
  },
  diyoboPasswordReset: {
    title: "Welcome Back!",
    msg:
      "You are attempting to sign-in with an account from diyobo.com. Diyobo is now affiliated with INCREDEVENT and we need to verify your account in our system. Please reset your password.",
    confirm: "Reset Password",
  },

  // buyers
  passwordUpdated: {
    msg: "Password Updated!",
  },
  suggestInterest: {
    msg:
      "Thanks for submitting a new interest! Our team needs to review all new interests/hobbies before adding them to our database. As soon as it is approved we will add it to your profile!",
  },
  interestsIncomplete: {
    title: "More Interests Needed",
    msg:
      "C'mon, we know you're more interesting than that! Go back to your profile and select at least 10 interests so we can show you people going to the events you're attending who like similar things.",
    confirm: "Go Back to Profile",
  },
  blacklistedInterest: {
    msg:
      "Unfortunately that interest has been blacklisted my the admins. It has been deemed to be offensive or inappropriate to our users.",
  },
  profileIncomplete: {
    title: "The Importance of a Profile",
    msg:
      "Filling out your profile is not mandatory. However, if you do fill in the info on this page you will have faster checkouts and more features will be available to you.",
    confirm: "Sounds Good",
  },
  checkoutPaymentFaild: {
    msg: "Payment Failed",
  },
  checkoutGeneralError: {
    msg: "Something went wrong. Please try again later.",
  },
  checkoutPaypalFailed: {
    msg: "PayPal payment failed. Please try again later.",
  },
  companyFailedToRetrieveEvents: {
    msg: "Failed to retrieve events for this company",
  },
  companyFailedToRetrieveInfo: {
    msg: "Failed to retrieve crucial information for this company",
  },
  companyInvalidUrl: {
    msg: "Server Error. URL link to company-page needs proper event URL.",
  },
  tradeSuccess: {
    msg: "Tickets away!",
  },
  totetalkDisabled: {
    title: "ToteTalk Disabled",
    msg:
      "It seems that you have ToteTalk disabled. Head over to your profile to enable it and start chatting with fellow event goers!",
    confirm: "Go Back to Profile",
  },
  loginToBuy: {
    title: "Whoops!",
    msg:
      "You need to login/register before you can purchase tickets to this event.",
  },
  paymentFailed: {
    msg:
      "That's weird. Your payment didn't go through. We really want to get you to this event so head back to double check and make sure everything was entered in correct.",
  },

  // planners
  companySavedUnverified: {
    title: "Changes Saved",
    msg:
      "Thank you for updating your Brand Settings. Your events will not be activated until we have verified your Account. You will be notified when your events are active.",
  },
  companySaved: {
    title: "Changes Saved",
    msg: "Thank you for updating your Brand Settings.",
  },
  brandAdded: {
    title: "New Brand Added",
    msg:
      "Look at you go. Your brand page has now been created and it will be as easy as one click for your customers to find all of your events.",
  },
  brandUpdated: {
    title: "Brand Info Updated",
    msg: "'Lovin' the updates. Your info is saved.",
  },
  idVerification: {
    title: "ID Verification",
    msg:
      "Thank you for verifying your identity. We will now redirect you to the next step in your event management journey.",
  },
  comp: {
    msg:
      "You have successfully created complimentry tickets. Click the ticket PDF icon below to download the tickets.",
  },
  createCompWillCall: {
    title: "Comps / WillCall Added",
    msg:
      "Your comps/will call slips have been created and sent to the email you entered. View the details on the tables below.",
  },
  printTicketsSuccess: {
    title: "Tickets Created",
    msg: "Print these bad boys off and lets go to the show!",
  },
  instantEmailTestSent: {
    msg: "A test email was sent to {email}.",
  },
  instantEmailSent: {
    msg:
      "Your email has been sent to {recipients} recipient(s). Reminder that ticket buyers can only recieve 1 email from your event every 24 hours.",
  },
  instantEmailSentBlacklisted: {
    msg:
      "Your email has been sent, but {blacklisted} ticket buyer(s) will not recieve the email. Reminder that ticket buyers can only recieve 1 email from your event every 24 hours.",
  },

  guestAdded: {
    msg: "Guest(s) are added to your Guest List!",
  },
  guestAddedUser: {
    msg: "You are added to the Guest List!",
  },
  guestEdited: {
    msg: "Guest is updated for your Guest List!",
  },
  guestSettings: {
    msg: "Your changes have been saved.",
  },
  guestlistFull: {
    title: "Guest List is Full",
    msg:
      "Our guest list is currently full, however you can still submit your party for our waitlist and we will notify you if a spot is becomes available. <br> <br>  Please Note: By submitting your information you are not guranteed a guestlist for this event.",
  },
  promoCodeAdded: {
    msg: "Your promo code was added successfully!",
  },
  promoCodeUpdated: {
    msg: "Your promo code has been updated!",
  },
  sellerAdded: {
    msg: "Your promoter was added successfully!",
  },
  sellerUnacrchived: {
    msg: "Your promoter was unarchived successfully!",
  },
  sellerAddedPrevious: {
    msg: "Added previous promoter(s) successfully!",
  },
  sellerAlreadyAdded: {
    msg: "You have already added a promoter with this email!",
  },
  sellerFound: {
    msg:
      "We found {promoter} in our users list! Would you like to auto-fill their information?",
  },
  willcall: {
    msg:
      "You have successfully created will call tickets. Click the print icon below to download the tickets.",
  },
  covidApplication: {
    msg:
      "Thank you for your submission. We will reach out to you once we review your application.",
  },
  bugReport: {
    msg: "Thanks for the report!",
  },
  deleteContact: {
    msg: "Are you sure to want to delete {email} from the database?",
    confirm: "Delete",
  },
  deleteUserPermissions: {
    msg: "Are you sure you want to remove {email} from your events?",
    confirm: "Delete",
  },
  leaveUserPermissions: {
    msg: "Are you sure you want to leave {invitee}'s events?",
    confirm: "Leave",
  },
  brandFinancialsWork: {
    title: "Under Maintenance",
    msg:
      "You may have noticed a big change to our event financials. We are in the process of improving our brand financials too! This change will take us a bit, but in the mean time all event financials are live.",
  },
};

export default ModalContent;
