<template>
  <div class="other-events">
    <div class="events-inner">
      <div class="events-heading">
        <h3>{{ title }}</h3>
      </div>
      <div class="events">
        <DiyoboEvent
          v-for="event in events"
          :key="event.url"
          :name="event.name"
          :image="event.images"
          :oldImage="`${service_url}${event.image}`"
          :date="event.start"
          :venue="event.venue"
          :url="event.url"
          :event="event"
          :private-venue="event.privateVenue"
          :tiers="event.tiers"
          :cancelled="event.cancelled"
        />
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
#app {
  .other-events {
    .events-inner {
      .events-heading {
        h3 {
          margin-bottom: 0.25em;
          font-size: 1.35em;
        }
      }
      // end events heading

      .events {
        padding-top: 0;
      }
    }
    // end events-inner
  }
  // end other-events
}
</style>

<script>
import DiyoboEvent from "../events/DiyoboEvent.vue";

export default {
  name: "other-events",
  components: {
    DiyoboEvent
  },
  props: {
    title: String,
    events: Array
  },
  data() {
    return {
      service_url: process.env.VUE_APP_IMAGE_URL
    };
  }
};
</script>
