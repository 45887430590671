import x5GMaps from "x5-gmaps";
import Vue from "vue";
// import Datepicker from "vuejs-datepicker";
import Vcalendar from "v-calendar";

// Vue.component("v-date-picker", DatePicker);
Vue.use(Vcalendar);
// Vue.component("datepicker", Datepicker);
Vue.use(x5GMaps, {
  key: "AIzaSyBcexezrA_GnkA1uAOFi4KNpQGSzbP7nbM",
  libraries: ["places"],
});
