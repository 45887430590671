<template>
  <div class="tabs">
    <div
      v-for="(tab, index) in tabs"
      :key="index"
      @click="handleTabClick(index)"
      :class="[
        {
          'tab-wrapper': true,
          active: isSelected(index),
          tabDisable: isTabDisabled(index),
        },
      ]"
    >
      <div class="tab-content">{{ tab }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "tab-tote",
  props: {
    tabs: Array,
    value: {
      type: Number,
      default: 0,
    },
    selected: {
      type: Number,
      default: 0,
    },
    tabDisable: {
      type: Array,
      description: "Array of disabled tab indices",
      default: () => [],
    },
  },
  watch: {
    selected(value) {
      this.$emit("input", value);
    },
  },
  methods: {
    isSelected(index) {
      return this.value === index;
    },
    isTabDisabled(index) {
      return this.tabDisable.includes(index);
    },
    handleTabClick(index) {
      if (!this.isTabDisabled(index)) {
        this.$emit("input", index);
        this.$emit("click", index);
      }
    },
  },
};
</script>

<!-- <template>
  <div class="tabs">
    <div
      v-for="(tab, index) in tabs"
      class="tab-wrapper"
      :class="[
        { active: selected > 0 ? selected === index : value === index },
        isTabDisable(index),
      ]"
      :key="index"
      v-on:click="click(index)"
    >
      <div class="tab-content">{{ tab }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "tab-tote",
  props: {
    tabs: Array,
    value: {
      type: Number,
      default: 0,
    },
    selected: {
      type: Number,
      default: 0,
    },
    tabDisable: {
      type: Array,
      description: "use to disable specific tab not required",
    },
  },
  watch: {
    selected(value) {
      this.$emit("input", value);
    },
  },
  methods: {
    isTabDisable(index) {
      return this.tabDisable && this.tabDisable.includes(index)
        ? "tabDisable"
        : "";
    },
    click(index) {
      if (this.tabDisable && this.tabDisable.includes(index)) {
        return;
      }
      this.$emit("click", index);
      this.$emit("input", index);
    },
  },
};
</script> -->

<style lang="less" scoped>
.tabs {
  display: flex;
  width: 100%;
  border: 1px solid @colors[tab-border];
  border-radius: 5px;
  overflow: hidden;
  .tab-wrapper {
    display: flex;
    width: 100%;
    justify-content: center;
    flex-direction: column;
    color: @colors[text];
    background: var(--content-dark);
    border-right: 1px solid @colors[tab-border];
    transition: 0.4s;
    cursor: pointer;
    &:nth-last-child(1) {
      border-right: 0;
    }
    &:hover {
      background: var(--content-light);
    }
    &:after {
      content: "";
      display: inline-block;
      width: 100%;
      height: 0;
      box-sizing: border-box;
      border-bottom: 5px solid var(--secondary-golden);
    }
    &.active {
      &:after {
        border-bottom: 5px solid var(--primary-golden);
      }
    }
    &.tabDisable {
      cursor: not-allowed;
      opacity: 0.5;
    }
    .tab-content {
      padding: 10px 10px;
      text-align: center;
    }
  }
  @media screen and (max-width: 800px) {
    flex-wrap: wrap;
    .tab-wrapper {
      border-right: 0;
    }
  }
}
</style>
