import axios from "axios";
import decode from "jwt-decode";
import {
  getLocationCookie,
  setLocationCookie
} from "@/helpers/location";
import ModalContent from "@/helpers/modals";

export const SESSION_COOKIE =
  process.env.VUE_APP_ENVIRONMENT === "staging" ? "ssession" : "session";

export function getCookies(req = null) {
  const cookie = req
    ? req.headers.cookie
      ? req.headers.cookie.split(";")
      : [""]
    : document.cookie.split(";");
  let cookies = {};

  cookie.forEach(key => {
    let keyVal = key.trim().split("=");
    cookies[keyVal[0]] = keyVal[1];
  });

  return cookies;
}

export function getSessionCookie(req = null) {
  return getCookies(req)[SESSION_COOKIE];
}

export async function createSession(username, password, app, admin) {
  let data;

  if (admin) {
    await axios
      .post("/auth/authenticate", {
        name: username,
        password: "",
        admin: admin
      })
      .then(resp => {
        data = resp.data;
      });
  } else {
    await axios
      .post("/auth/authenticate", {
        name: username,
        password: password
      })
      .then(resp => {
        data = resp.data;
      }).catch(({response}) => {
        throw response.data.error;
      })
  }

    storeSessionData(data, app);
}

export function storeSessionData(data, app) {
  // get session data and store it in app data
  let dataInfo = decode(data.token);

  if (dataInfo.activated) {
    // create a cookie for session data
    setSessionCookie(data.token);
    let location = data.user.location;
    if (location && !getLocationCookie()) {
      if (location.split("-").length === 3) {
        location = location.replace(/\-/g, "|");
      }
      const parts = location.split("|");
      if (parts.length === 3) {
        setLocationCookie(parts[0], parts[1], parts[2]);
      }
    }
    app.$store.commit("setUser", data.user);
    app.$store.commit("setData", dataInfo);
  } else {
    app.$bus.$emit("redirectVerification", data.user._id);
    // app.$bus.$emit("alert", ModalContent.accountNotActivated);
  }
}

export async function setWiteListRedirectSession(url, redirectWlId, app) {
  let data;
  await axios
    .post("/auth/authenticate-whitelist-user", {
      url: url,
      redirectWlId: redirectWlId
    })
    .then(resp => {
      data = resp.data;
    });

  if (data.error) {
    throw data.error;
  } else {
    storeSessionData(data, app);
    await axios
      .post("/white-label/delete-redirect", {
        redirectWlId: redirectWlId
      })
      .then(response => {
        if (response.status != 200) {
          console.log(response);
        }
      });
  }
}

export async function adminUserSession(id, app) {
  let data;
  let dataInfo;
  await axios
    .post("/auth/authenticate-admin-as-user", {
      id: id
    })
    .then(resp => {
      data = resp.data;
    });

  if (data.error) throw data.error;
  else {
    dataInfo = decode(data.token);
    setSessionCookie(data.token);

    app.$store.commit("setUser", data.user);
    app.$store.commit("setData", dataInfo);
  }
}

export function destroySession(app) {
  app.$axios.post("/auth/deauthenticate").then(() => {
    app.$store.dispatch("logout");

    switch (process.env.VUE_APP_ENVIRONMENT) {
      case "development":
        document.cookie = `${SESSION_COOKIE}=${getSessionCookie()};path=/;domain=localhost;expires=Thu, 18 Dec 2013 12:00:00 UTC`;
        break;
      case "staging":
        document.cookie = `${SESSION_COOKIE}=${getSessionCookie()};path=/;domain=.staging.incredevent.com;expires=Thu, 18 Dec 2013 12:00:00 UTC`;
        break;
      default:
        document.cookie = `${SESSION_COOKIE}=${getSessionCookie()};path=/;domain=.worrestudiosboxoffice.com;expires=Thu, 18 Dec 2013 12:00:00 UTC`;
        break;
    }
  });
}

export function updateSession(app, callback) {
  let dataInfo;
  let data;

  axios
    .post("/auth/update-session", {
      email: app.emailInput
    })
    .then(resp => {
      data = resp.data;

      setSessionCookie(data.token);

      // get session data and store it in app data
      dataInfo = decode(data.token);
      app.$store.state.user.name =
        data.user.first_name + " " + data.user.last_name;
      app.$store.state.user.email = data.user.email;
      app.$store.state.user.phone = data.user.phone;
      app.$store.state.user.link = data.user.link;
      app.$store.state.user.isAuthenticated = true;
      app.$store.state.session_id = dataInfo.session_id;
      app.$store.state.user.activated = dataInfo.activated;
      app.$store.state.profileComplete = dataInfo.profileComplete;

      if (callback) callback();
    });
}

export function setSessionCookie(session) {
  const expirationDate = new Date();
  expirationDate.setDate(expirationDate.getDate() + 7);
  switch (process.env.VUE_APP_ENVIRONMENT) {
    case "development":
      document.cookie = `${SESSION_COOKIE}=${session};path=/;domain=localhost;expires=${expirationDate.toUTCString()};`;
      break;
    case "staging":
      document.cookie = `${SESSION_COOKIE}=${session};path=/;domain=.staging.incredevent.com;expires=${expirationDate.toUTCString()};`;
      break;
    default:
      document.cookie = `${SESSION_COOKIE}=${session};path=/;domain=.worrestudiosboxoffice.com;expires=${expirationDate.toUTCString()};`;
      break;
  }
}

export function clearAuthToken() {}

export function isAuthenticated(self) {
  let cookies = getCookies();

  return cookies[SESSION_COOKIE] && self.$store.state.user.isAuthenticated;
}
