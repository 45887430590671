<template>
  <div
    v-if="chats"
    id="unread-messages"
    :class="{ hidden: isHidden() }"
    v-on:click="redirect"
  >
    <div class="wrapper">
      <div ref="dialog" class="inner">
        <div class="icon">
          <font-awesome-icon icon="comment" />
        </div>
        <div class="invites-notif">{{ chats }}</div>
        <div class="message">
          <h3 v-if="chats === 1" class="title">You have 1 unread chat!</h3>
          <h3 v-else class="title">You have {{ chats }} unread chats!</h3>
          <p v-if="chats === 1" class="subtitle">Click here to view it!</p>
          <p v-else class="subtitle">Click here to view them!</p>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
#unread-messages {
  position: fixed;
  width: 80px;
  height: 80px;
  bottom: 25px;
  right: 25px;
  z-index: 1000;
  cursor: pointer;
  transition: 0.4s;

  .wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    padding: 2px;
    border-radius: 80px;
    background-color: #4a639e;
    background-image: linear-gradient(160deg, #4eb95e, #4a639e);
    overflow: hidden;

    .inner {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      height: 100%;
      padding: 0 10px;
      background-color: #27314a;
      border-radius: 80px;
      overflow: hidden;

      .icon {
        margin: auto;
        padding: 0 10px;
        font-size: 2em;
      }

      .invites-notif {
        position: absolute;
        width: 15px;
        height: 15px;
        left: 48px;
        top: 28px;
        border-radius: 100%;
        background-color: #b85353;
        pointer-events: none;
        font-size: 0.2em;
        text-align: center;
      }

      .message {
        width: 0;
        padding: 0;
        word-break: keep-all;
        overflow: hidden;
        white-space: nowrap;
        transition: 0.4s;

        .title {
          margin: auto;
        }

        .subtitle {
          margin: auto;
        }
      }
    }
  }

  &:hover {
    width: 320px;

    .wrapper {
      .inner {
        .message {
          width: 100%;
          padding: 0 10px;
        }
      }
    }
  }

  &.hidden {
    display: none;
  }
}
</style>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { faComment } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faComment);

export default {
  name: "unread-chats",
  components: {
    FontAwesomeIcon
  },
  props: {
    chats: Number
  },
  methods: {
    redirect() {
      this.$router.push("/tote-talk");
    },
    isHidden() {
      return this.$route.path.startsWith("/tote-talk");
    }
  }
};
</script>
