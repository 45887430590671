<template>
  <modal title="Pdf Viewer" :active="active" v-on:close="$emit('cancel')">
    <div class="alert-message">
      <div id="adobe-dc-view"></div>

      <!-- <object
        data=""
        type="application/pdf"
        id="objPdf"
        frameborder="0"
        width="100%"
        height="600px"
        style="padding: 20px"
      >
        <div id="pdf"></div>
      </object> -->
      <!-- <object data="" type="application/pdf" id="pdfObj"> -->
      <!-- <iframe
        src=""
        type="application/pdf"
        id="pdf"
        width="100%"
        height="100%"
      /> -->
      <!-- </object> -->

      <!-- <object data="" type="application/pdf" id="pdf"></object> -->
    </div>
    <!-- <div class="button-wrapper">
      <diyobo-button
        type="secondary"
        txt="Cancel"
        v-on:click="$emit('cancel')"
      />
    </div> -->
  </modal>
</template>

<style lang="less" scoped>
.modal {
  .modal-inner {
    .modal-dialog {
      // height: 80% !important;
      // width: 100% !important;
      // max-width: initial !important;
      // top: 25px !important;
      .modal-body {
        height: calc(100% - 114px);

        .modal-content {
          height: 100%;
        }
        .alert-message {
          height: inherit;
          display: flex;
          justify-content: center;
          height: 500px;
          // width: ;
        }

        .loader {
          border: 8px solid #f3f3f3;
          border-top: 8px solid #4eb95e;
          border-radius: 50%;
          width: 60px;
          height: 60px;
          animation: spin 1s ease-in-out infinite;
          margin-bottom: 20px;
        }

        @keyframes spin {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
      }
    }
  }
}
</style>

<script>
import Modal from "@/components/Modal.vue";
import DiyoboButton from "@/components/DiyoboButton.vue";

export default {
  name: "alert-modal",
  components: {
    Modal,
    DiyoboButton,
  },
  props: {
    active: Boolean,
  },
};
</script>
