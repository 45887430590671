<template>
  <modal
    title="Login to Your Account"
    :active="active"
    v-on:close="$emit('dismiss')"
  >
    <div class="error-label" v-if="modalError">{{ checkError }}</div>
    <diyobo-input
      type="email"
      label="Email"
      placeholder="example@domain.com"
      v-model="name"
      autocomplete="off"
      :error="modalError"
      :required="true"
    />
    <div class="forgot-password-wrapper">
      <div class="forgot-password" v-on:click="$emit('forgot')">
        Forgot Password?
      </div>
    </div>
    <diyobo-input
      type="password"
      label="Password"
      placeholder="Password"
      v-model="password"
      autocomplete="off"
      :error="modalError"
      :required="true"
      v-on:keydown.enter="$emit('confirm')"
    />
    <template v-slot:footer>
      <div class="buttons-wrapper">
        <div class="register-wrapper">
          <div class="register" v-on:click="$emit('register')">Register</div>
        </div>
        <diyobo-button
          type="secondary"
          txt="Login"
          v-on:click="$emit('confirm')"
        />
      </div>
    </template>
  </modal>
</template>

<script>
import Modal from "@/components/Modal.vue";
import DiyoboInput from "@/components/DiyoboInput.vue";
import DiyoboButton from "@/components/DiyoboButton.vue";

export default {
  name: "login-modal",
  components: {
    Modal,
    DiyoboInput,
    DiyoboButton
  },
  props: {
    active: Boolean,
    error: String,
    height: String,
    signup: Boolean
  },
  data() {
    return {
      name: "",
      password: ""
    };
  },
  computed: {
    checkError() {
      if (this.error) return this.error;
      else return false;
    },
    modalError() {
      if (this.error) return true;
      else return false;
    }
  }
};
</script>

<style lang="less" scoped>
.modal {
  .modal-inner {
    .modal-dialog {
      .modal-body {
        .error-label {
          margin-bottom: 0.75em;
          color: #e60022;
          text-align: center;
          font-weight: 600;
        }

        .forgot-password-wrapper {
          position: absolute;
          right: 55px;
          z-index: 2;

          .forgot-password {
            color: var(--primary-golden);
            font-weight: 500;

            &:hover {
              cursor: pointer;
            }
          }
        }

        .modal-footer {
          .buttons-wrapper {
            display: flex;
            width: 100%;
            justify-content: space-between;

            .register-wrapper {
              display: flex;
              justify-content: flex-end;

              .register {
                margin: auto;
                color: #4cac55;
                font-weight: 500;
                align-items: flex-start;

                &:hover {
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
