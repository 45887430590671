<template>
  <modal title="Contact Us" :active="active" v-on:close="dismiss">
    <h3 class="title">{{ title }}</h3>
    <div class="error-label" v-if="modalError">{{ checkError }}</div>
    <FormulateInput
      v-if="showOption"
      type="select"
      :options="{
        tickettote: 'Ticket Questions',
        planner: 'Event Details Questions',
      }"
      @input="selectContactType"
    />
    <FormulateForm
      v-if="formValues.emailType === 'second'"
      name="contact-coordinator"
      v-model="formValues"
      @submit="handleSubmit"
      #default="{ isLoading }"
    >
      <FormulateInput
        type="text"
        name="name"
        label="Name"
        placeholder="Name"
        validation="required"
      />
      <FormulateInput
        type="text"
        name="email"
        label="Email"
        placeholder="Email"
        validation="email|required"
      />
      <FormulateInput
        type="textarea"
        name="message"
        label="Message"
        placeholder="Message"
        validation="required"
      />
      <FormulateInput type="submit" name="Submit" :loading="isLoading" />
    </FormulateForm>
    <FormulateForm
      v-if="formValues.emailType === 'first'"
      name="contact-coordinator"
      v-model="formValues"
      @submit="handleSubmit"
      #default="{ isLoading }"
      autocomplete="false"
    >
      <FormulateInput
        type="text"
        name="name"
        label="Name"
        placeholder="Name"
        validation="required:trim"
        autocomplete="false"
      />
      <FormulateInput
        type="text"
        name="email"
        label="Email"
        placeholder="Email"
        validation="email|required"
        autocomplete="false"
      />
      <FormulateInput
        type="textarea"
        name="message"
        label="Message"
        placeholder="Message"
        validation="required:trim"
        autocomplete="false"
      />
      <FormulateInput type="submit" name="Submit" :loading="isLoading" />
    </FormulateForm>
  </modal>
</template>

<script>
import Modal from "@/components/Modal.vue";

export default {
  name: "contact-coordinator-modal",
  components: {
    Modal
  },
  props: {
    active: Boolean,
    error: String,
    height: String,
    signup: Boolean,
    event: String,
    title: String,
    showOption: Boolean,
  },
  data() {
    return {
      formValues: {
        name: "",
        emailType: "second",
        email: "",
        message: ""
      },
      contactType: "planner"
    };
  },
  computed: {
    checkError() {
      if (this.error) return this.error;
      else return false;
    },
    modalError() {
      if (this.error) return true;
      else return false;
    }
  },
  watch: {
    "formValues.email"(newVal) {
      this.removeSpaces(newVal, "onlyemail");
    }
  },
  methods: {
    removeSpaces(val, type) {
      if (val) {
        switch (type) {
          case "onlyemail":
            this.formValues.email = val.replace(/\s/g, "");
            break;
          default:
            break;
        }
      }
    },
    selectContactType(e) {
      this.contactType = e;
    },
    async handleSubmit(values) {
      if (this.contactType === "planner") {
        await new Promise(() => {
          this.$emit(
            "confirmPlanner",
            this.event,
            values.name,
            values.email,
            values.message
          );
        });
      } else {
        await new Promise(() => {
          this.$emit(
            "confirmTickettote",
            this.event,
            values.name,
            values.email,
            values.message
          );
        });
      }
    },
    dismiss() {
      this.$emit("dismiss");
    }
  }
};
</script>

<style lang="less" scoped>
.modal {
  .modal-inner {
    .modal-dialog {
      .modal-body {
        .modal-content {
          .error-label {
            margin-bottom: 0.75em;
            color: #e60022;
            text-align: center;
            font-weight: 600;
          }

          .title {
            margin-top: 0 !important;
          }
        }
      }
    }
  }
}
</style>