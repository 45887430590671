<template>
  <div class="pagination-container">
    <ul class="pagination">
      <li class="page-item" :class="{ disabled: currentPage === 1 }">
        <span class="page-link" @click="prevPage">Previous</span>
      </li>
      <li v-if="currentPage > 1" class="page-item">
        <span class="page-link" @click="gotoPage(1)">1</span>
      </li>
      <li v-if="currentPage > 3" class="page-item">
        <span class="page-link disabled">...</span>
      </li>
      <li v-for="page in pagesToShow" :key="page" :class="{ active: page === currentPage }" class="page-item">
        <span class="page-link" @click="gotoPage(page)">{{ page }}</span>
      </li>
      <li v-if="currentPage < totalPages - 2" class="page-item">
        <span class="page-link disabled">...</span>
      </li>
      <li v-if="currentPage < totalPages" class="page-item">
        <span class="page-link" @click="gotoPage(totalPages)">{{ totalPages }}</span>
      </li>
      <li class="page-item" :class="{ disabled: currentPage === totalPages }">
        <span class="page-link" @click="nextPage">Next</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "Pagination",
  props: {
    currentPage: {
      type: Number,
      required: true,
    },
    totalPages: {
      type: Number,
      required: true,
    },
  },
  methods: {
    prevPage() {
      if (this.currentPage > 1) {
        this.$emit('page-change', this.currentPage - 1);
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.$emit('page-change', this.currentPage + 1);
      }
    },
    gotoPage(page) {
      if (page >= 1 && page <= this.totalPages) {
        this.$emit('page-change', page);
      }
    },
  },
  computed: {
    pagesToShow() {
      const pages = [];
      let start = Math.max(1, this.currentPage - 1);
      if(start == 1 && start !== this.currentPage){
        start++;
      }
      let end = Math.min(this.totalPages, this.currentPage + 1);
      if(this.currentPage + 1 == this.totalPages){
        end--;
      }

      for (let i = start; i <= end; i++) {
        pages.push(i);
      }

      return pages;
    },
  },
};
</script>

<style lang="less" scoped>
.pagination-container {
  text-align: center;
  margin-top: 20px;
}

.pagination {
  display: inline-flex;
  padding-left: 0;
  margin: 0;
  border-radius: 10px;
  list-style-type: none;
}

.page-item {
  display: inline;
  padding: 0;
  margin-right: 5px;
  border: 1px solid var(--border);
  border-radius: 5px;
}

.page-link {
  display: block;
  padding: 10px 15px;
  line-height: 1.42857143;
  color: var(--text);
  text-align: center;
  text-decoration: none;
  border-radius: 5px;
  background-color: var(--gray-7);
}

.page-link:hover {
  background-color: var(--primary-blue);
  color: var(--primary-black);
}

.page-link:focus {
  background-color: var(--primary-blue);
  color: var(--primary-black);
  outline: none;
}

.page-item.active .page-link {
  background-color: var(--gray-5);
  color: var(--text);
}

.page-item.disabled .page-link {
  pointer-events: none;
  background-color: var(--gray-3);
  color: var(--text);
}
</style>