<template>
  <div class="event">
    <div class="event-inner">
      <router-link :to="'/' + url">
        <div class="event-banner" ref="event-banner" v-if="imgPref == ''">
          <img :src="defaultImg" :alt="name" />
          <img
            v-if="cancelled"
            class="cancelled"
            src="/img/cancelled.png"
            alt="cancelled"
          />
        </div>
        <div class="event-banner" ref="event-banner" v-if="imgPref != ''">
          <img :src="dashIcon" :alt="name" />
          <img
            v-if="cancelled"
            class="cancelled"
            src="/img/cancelled.png"
            alt="cancelled"
          />
        </div>
        <div class="event-tag">
          {{ eventType }}
        </div>
        <div class="event-city-tag">
          {{ eventLocation }}
        </div>
      </router-link>
      <div class="bottom-with-long-event-name" @click="expand">
        <div v-if="name.length <= 34" class="event-title">{{ name }}</div>
        <div v-if="name.length > 34" class="small-event-title">{{ name }}</div>
        <div class="event-date" v-if="event.hide_event_dates || event.replay">
          <span>No dates available</span>
        </div>
        <div class="event-date" v-else>
          <span v-if="isMultiDate" :class="['frequency', event.frequency]">
            {{ eventFrequency }}
          </span>
          <span v-if="isMultiDate" :class="['next', event.frequency]">
            Next:
          </span>
          {{ isSameDate }}
        </div>
        <div class="event-details" v-if="showPrice">
          <div class="event-details-inner" :class="{ active: expanded }">
            <div class="detail" v-if="priceLow == priceHigh">
              <!-- ${{ priceLow }} -->
              {{ formatMoney(priceLow) }}
            </div>
            <!-- <div class="detail" v-else>${{ priceLow }} - ${{ priceHigh }}</div> -->
            <div class="detail" v-else>
              {{ formatMoney(priceLow) }} - {{ formatMoney(priceHigh) }}
            </div>
            <div class="detail">
              {{ venue ? venue : "Venue Hidden" }}
            </div>
            <a href="#" class="detail" v-on:click.prevent="contact">Contact</a>
            <router-link :to="'/' + url" class="detail">
              Buy Tickets
            </router-link>
          </div>
        </div>
        <div class="event-details-toggle" :class="{ active: expanded }">
          <font-awesome-icon style="color: #babcbf" icon="angle-down" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import dateFormat from "dateformat";
import axios from "axios";
import dayjs from "dayjs";
var timezone = require("dayjs/plugin/timezone");
dayjs.extend(timezone);

import { convert } from "@/helpers/currencyConversion";

library.add(faAngleDown);

export default {
  name: "diyobo-event",
  components: {
    FontAwesomeIcon,
  },
  props: {
    name: String,
    date: Object,
    image: Object,
    venue: String,
    url: String,
    oldImage: String,
    tiers: Array,
    cancelled: Boolean,
    privateVenue: Boolean,
    event: Object,
    currencyType: String,
  },
  data() {
    return {
      expanded: false,
      timeout: null,
      imgPref: "",
      dashIcon: process.env.VUE_APP_IMAGE_URL + this.image.hero,
      defaultImg: process.env.VUE_APP_IMAGE_URL + "media/img/venue/default.png",
      priceHigh: "",
      priceLow: "",
      currencyRate: 1,
      newLowPrice: null,
      newHighPrice: null,
    };
  },
  computed: {
    showPrice() {
      return true;
      return this.cadOption !== null && this.usdOption !== null;
    },
    eventBottomClassName() {
      return this.expanded ? "bottom" : "bottom-with-long-event-name";
    },
    //TODO:remind me when i push
    isSameDate() {
      let timezone = dayjs.tz.guess();

      if (this.event.frequency !== "SINGLE_DATE") {
        let date = this.event.dates[0].start.hasOwnProperty("$date")
          ? +this.event.dates[0].start.$date.$numberLong
          : this.event.dates[0].start;
        let timezone = dayjs.tz.guess();

        let dateString = dayjs(date);
        return dateString.format("ddd, MMM DD, YYYY");
      } else {
        let timezone = dayjs.tz.guess();
        let formattedStartDate = dayjs(
          +this.event.start.$date.$numberLong
        ).format("ddd, MMM DD, YYYY");
        let formattedEndDate = dayjs(+this.event.end.$date.$numberLong).format(
          "ddd, MMM DD, YYYY"
        );

        return `${formattedStartDate} - ${formattedEndDate}`;
      }
    },
    currentStoreCurrency() {
      return this.$store.state.currency;
    },
    cadOption() {
      return this.$store.state.cadCurrencyOption;
    },
    usdOption() {
      return this.$store.state.usdCurrencyOption;
    },
    formattedDate() {
      return dateFormat(+this.date, "ddd, mmm dS, yyyy");
    },
    isMultiDate() {
      return this.event.frequency && this.event.frequency !== "SINGLE_DATE";
    },
    eventType() {
      if (!this.event.eventType) {
        return "In-Person";
      }

      switch (this.event.eventType) {
        case "IN_PERSON":
          return "In-Person";
        case "VIRTUAL":
          return "Virtual";
        case "HYBRID":
          return "Hybrid";
      }
    },
    eventLocation() {
      return this.event.city;
    },
    eventFrequency() {
      if (!this.event.frequency) {
        return "One Date Only";
      }

      switch (this.event.frequency) {
        case "SINGLE_DATE":
          return "One Date Only";
        case "MULTI_DATE":
          return "Multi-Date";
        case "DAILY":
          return "Daily";
        case "WEEKLY":
          return "Weekly";
        case "MONTHLY":
          return "Monthly";
      }
    },
  },

  created() {
    this.imgPref = this.dashIcon;
  },
  async mounted() {
    this.tiers.forEach((ele) => {
      if (this.priceHigh == "") {
        this.priceHigh = ele.price;
      } else if (this.priceHigh < ele.price) {
        this.priceHigh = ele.price;
      }

      if (this.priceLow == "") {
        this.priceLow = ele.price;
      } else if (this.priceLow > ele.price) {
        this.priceLow = ele.price;
      }
    });
  },
  methods: {
    formatMoney(price) {
      //return `$${parseFloat(price).toFixed(2)}`;

      if (this.cadOption === null && this.usdOption === null) {
        return;
      }
      let currency = null;

      if (this.event.currency === "usd") {
        if (this.usdOption) {
          currency = this.usdOption.find(
            (x) => x.label === this.currentStoreCurrency.name
          );
        }
      } else {
        if (this.cadOption) {
          currency = this.cadOption.find(
            (x) => x.label === this.currentStoreCurrency.name
          );
        }
      }

      return convert(currency.value, price, currency.label);
    },

    expand() {
      if (this.expanded) this.expanded = false;
      else this.expanded = true;
    },
    close() {
      if (this.expanded) {
        this.expanded = false;
      }
    },
    contact() {
      // this.$bus.$emit("contact-coordinator", this.url, this.name);
      Beacon("open");
    },
  },
};
</script>

<style lang="less" scoped>
.event {
  flex-grow: unset;
  margin: 1em 0.7533%;
  // padding: 6px;
  position: relative;
  width: calc(31.5% - 11px);
  background-color: var(--event-background);
  border: 1px solid var(--event-border);
  border-radius: 4px;
  box-shadow: var(--event-boxshadow);
  overflow: hidden;

  @media screen and (max-width: 971px) {
    margin: 1em 0.75%;
    width: calc(48.5% - 14px);
  }

  @media screen and (max-width: 724px) {
    margin: 1em 1%;
    width: 98% !important;
  }

  .event-inner {
    cursor: pointer;
    position: relative;

    .bottom {
      background: var(--event-tiles-background);
      padding: 8px;
    }
    .bottom-with-long-event-name {
      background: var(--event-tiles-background);
      padding: 8px;
      // height: 57px;
    }

    .event-banner {
      width: 100%;
      aspect-ratio: 16/9;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: 0.4s;

        &.cancelled {
          position: absolute;
          height: 240px;
          right: 2px;
          top: -22px;
        }
      }
    } // end event-banner

    .event-tag {
      position: absolute;
      top: 8px;
      right: 8px;
      padding: 2px 8px;
      color: var(--text);
      background-color: var(--content-background);
      font-size: 14px;
      font-weight: bold;
      border-radius: 4px;
    }
    .event-city-tag {
      position: absolute;
      top: 8px;
      left: 8px;
      padding: 2px 8px;
      color: var(--text);
      background-color: var(--content-background);
      font-size: 14px;
      font-weight: bold;
      border-radius: 4px;
    }

    .event-title {
      margin: 0.25em 0;
      font-family: inherit;
      font-size: 1.18em;
      font-weight: 700;
      letter-spacing: -0.05em;
    }
    .small-event-title {
      margin: 0.25em 0;
      font-family: inherit;
      font-size: 0.9em;
      font-weight: 700;
      letter-spacing: -0.05em;
      height: 26px;
    }
    .none-event-date {
      height: 21.5px;
    }

    .event-date {
      font-family: inherit;
      color: #babcbf;
      display: flex;
      gap: 8px;

      .frequency {
        text-transform: capitalize;
        color: white;
        padding: 2px 4px;
        font-size: 12px;
        font-weight: bold;
        border-radius: 4px;

        &.MULTI_DATE {
          background: #006eb4;
        }
        &.DAILY {
          background: #662389;
        }
        &.WEEKLY {
          background: #f48201;
        }
        &.MONTHLY {
          background: #f50407;
        }
      }

      .next {
        &.MULTI_DATE {
          color: #006eb4;
        }
        &.DAILY {
          color: #662389;
        }
        &.WEEKLY {
          color: #f48201;
        }
        &.MONTHLY {
          color: #f50407;
        }
      }
    } // end event-date

    .event-details {
      margin: 0 -6px;

      .event-details-inner {
        display: flex;
        flex-wrap: wrap;
        max-height: 0;
        overflow: hidden;
        transition: 0.4s;

        &.active {
          margin-top: 16px;
          max-height: 1000px;
        }

        .detail {
          margin: 1.25%;
          padding: 0.5em 0;
          width: 47%;
          background: #454951;
          text-align: center;
          text-transform: capitalize;
          color: #fff;
          border-radius: 4px;
        }

        a.detail {
          position: relative;
          z-index: 1;
          width: 46.5%;

          color: white;
          filter: brightness(1.1);
          background-color: #222222;
          background: var(--event-detail-background);
          border: 1px solid var(--event-detail-border);
          overflow: hidden;

          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            width: 101%;
            height: 100%;
            background: linear-gradient(#353535, #202020);
            opacity: 0;
            transition: 0.4s;
          }

          &:hover::before {
            opacity: 1;
          }
        }
      } // end event-details inner
    } // end event-details

    .event-details-toggle {
      position: relative;
      top: -1em;
      z-index: 3;
      left: 80%;
      width: 20%;
      max-height: 0px;
      text-align: right;
      color: #666666;
      font-size: 1.25em;
      cursor: pointer;
      transition: 0.6s;

      &.active {
        top: 0;
        max-height: 20px;

        svg {
          transform: rotateX(180deg);
        }
      }
    } // end event-details-toggle
  } // end event-inner
}
</style>
